/* .reset_layout_res{
    position: absolute;
    width: 100%;
    border: 1px solid black;
    
    height: 45px;
    top: 19px;
} */
.changePass{
    z-index: 15;
    cursor: pointer;
    position: absolute;
    width: 45px;
    height: 45px;
    right: 1vw;
    bottom: 7vh;
}