.mediaViewInfo {
    --web-view-name: tax page mob one time;
    --web-view-id: tax_page_mob_one_time;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: tax_page_mob_one_time;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#tax_page_mob_semi{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    --web-view-name: tax page mob one time;
    --web-view-id: tax_page_mob_one_time;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#UOTMQSA_semi_task_mob {
    z-index: 10;
    position: absolute;
    width: 21.211px;
    height: 474.803px;
    right: 5px;
    top: 73.235px;
    overflow: visible;
}
#overdue_semi_task_mob {
    transform: translate(-346.345px, -62.235px) matrix(1, 0, 0, 1, 327.556, 85.235) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0, 0, 0, 1);
}
#Upcoming_semi_task_mob {
    transform: translate(-346.345px, -72.235px) matrix(1, 0, 0, 1, 329.556, 176.2415) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#monthly_semi_task_mob {
    transform: translate(-346.345px, -62.235px) matrix(1, 0, 0, 1, 332.1871, 253.869) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
}
#quaterly_semi_task_mob {
    transform: translate(-346.345px, -62.235px) matrix(1, 0, 0, 1, 332.556, 335.4718) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
}
#semi_semi_task_mob {
    transform: translate(-346.345px, -62.235px) matrix(1, 0, 0, 1, 320.556, 419.3927) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(235, 56, 61, 1);
}
#annual_lbl_semi_task_mob {
    transform: translate(-346.345px, -62.235px) matrix(1, 0, 0, 1, 336.6871, 507.0382) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4, 4, 4, 1);
}
#ot_semi {
    fill: rgba(255,255,255,1);
}
.ot_semi {
    position: fixed;
    overflow: hidden;
    width: calc(100% - 10%);
    height: 120%;
    top: 58px;
    border: 1px ridge rgba(252,135,135,1);
    border-radius: 22px;
}

#undesrline_semi_mob {
    fill: transparent;
		stroke: rgba(235, 56, 61, 1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
}
.undesrline_semi_mob {
    overflow: visible;
		position: absolute;
		width: 1px;
		height: 89px;
		left: 0px;
		top: 322.265px;
		transform: matrix(1,0,0,1,0,0);
}
