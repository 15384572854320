#user_card {
    cursor: pointer;
    position: relative;
    width: 174px;
    height: 191px;
    display: inline-block;
    margin: 20px;
    left: 0px;
    top: 36px;
    overflow: visible;
}
#card_over_all_bg {
    fill: transparent;
}
.card_over_all_bg {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 191px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#card_bg_design {
    position: absolute;
    width: 174px;
    height: 49px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#pfp_grp {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 64px;
    top: 25px;
    overflow: visible;
}
#pfp_behind_circle {
    fill: rgba(255,255,255,1);
    stroke: #fc8787;
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pfp_behind_circle {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 63px;
    left: 0px;
    top: 0px;
}
#profile_pic_card {
    position: absolute;
    width: 41px;
    height: 41px;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    overflow: hidden;
}
#user_email_img_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 120px;
    overflow: visible;
}
#user_email_lbl {
    left: 37px;
    top: 120px;
    position: absolute;
    overflow: visible;
    width: 134px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#user_phone_img_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 152px;
    overflow: visible;
}
#user_phone_lbl {
    left: 37px;
    top: 155px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#manager_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 60px;
    overflow: visible;
}
#Admin_lbl {
    left: 37px;
    top: 60px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#user_access_img_lbl {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 12px;
    top: 90px;
    overflow: visible;
}
#user_access_lbl {
    left: 37px;
    top: 90px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#user_name_lbl {
    top: 20px;
    position: absolute;
    overflow: hidden;
    width: 174px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
