.salary_overall_container{
    position: absolute;
    width: calc(100% - 547px);
    height: calc(100% - 90px);
    left: 182px;
    top: 50px;
}
#detIAIL_HEADING {
    position: absolute;
    width: 100%;
    top: 50px;
    height: 41px;
    overflow: visible;
}
#detail_bg {
    fill: rgba(255,255,255,1);
}
.detail_bg {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 49px;
    left: 0px;
    top: 0px;
}
.salary_heading{
    position: absolute;
    width: 100%;
    height: 49px;
    display: inline-flex;
}
#from_date_lbl {
    top: 10px;
    position: relative;
    overflow: visible;
    width: 200px;
    min-width: 90px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#to_date_lbl {
    top: 10px;
    position: relative;
    overflow: visible;
    width: 135px;
    min-width: 85px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#amount_lbl {
    top: 10px;
    position: relative;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#name_lbl {
    top: 10px;
    position: relative;
    overflow: visible;
    min-width: 160px;
    width: 200px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#EID_lbl {
    left: 8px;
    top: 10px;
    position: relative;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#status_lbl {
    top: 10px;
    position: relative;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_lbl_dslaary {
    top: 10px;
    position: relative;
    overflow: visible;
    min-width: 85px;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#paid_date_lbl_dslaary {
    top: 10px;
    position: relative;
    overflow: visible;
    min-width: 85px;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#salary_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    top: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#salary_scroll::-webkit-scrollbar{
    display: none;
}
#card_1_salary_page_grp {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 41px;
    top: 7px;
    overflow: visible;
}
#card_1_bg {
    fill: rgba(255,255,255,1);
}
.card_1_bg {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 49px;
    left: 0px;
    top: 0px;
}
.salary_data{
    position: absolute;
    width: 100%;
    height: 31px;
    display: inline-flex;
}
#name_salary {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 200px;
    min-width: 160px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#from_date_salary {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    min-width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#amount_salary {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 150px;
    min-width: 73px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#EID_salary {
    left: 9px;
    top: 11px;
    position: relative;
    overflow: visible;
    width: 120px;
    min-width: 110px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.status_sal{
    position: relative;
    width: 122px;
    height: 41px;
}
.to_pay_salary {
    position: absolute;
    overflow: visible;
    width: 122px;
    height: 41px;
    top: 0px;
}
#status_salary {
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
.status_sword{
    width: 122px;
    text-align: center;
}
#to_date {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    min-width: 90px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_timesalary {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 200px;
    min-width: 85px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#paid_date_timesalary {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    min-width: 85px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#add_btn_salary {
    cursor: pointer;
    position: absolute;
    width: 26px;
    z-index: 7;
    height: 26px;
    left: 85%;
    overflow: visible;
}
#add_bg_Add_salary {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_salary {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_salary {
    fill: rgba(0,0,0,1);
}
.Add_salary {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#lbl_salary {
    left: 89%;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}