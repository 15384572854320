.mediaViewInfo {
    --web-view-name: extended nav finance;
    --web-view-id: extended_nav_finance;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: extended_nav_finance;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#extended_nav_finance {
    position: absolute;
    width: 293px;
    height: calc(100vh - 60px);
    overflow: visible;
    --web-view-name: extended nav finance;
    --web-view-id: extended_nav_finance;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Group_3 {
    position: absolute;
    width: 246px;
    height: 717px;
    left: 30px;
    top: 26px;
    overflow: visible;
}

#side_nav_bar_border {
    fill: rgba(235,56,61,1);
    opacity: 0.8;
    z-index: 1;
}

.side_nav_bar_border {
    position: absolute;
    overflow: visible;
    width: 246px;
    height: 717px;
    left: 0px;
    top: 0px;
}

#over_all_grp_expand {
    position: absolute;
    width: 195px;
    height: calc(100vh - 145px);
    left: 26px;
    top: 28px;
    overflow: visible;
    display: grid;
}

#side_logo_icon_expand {
    cursor: pointer;
    position: relative;
    width: 70px;
    height: 70px;
    left: 62px;
    border-radius: 50%;
    overflow: hidden;
}

#menu_name_pc {
    position: relative;
    overflow: visible;
    width: 196px;
    height: 19px;
    text-align: center;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#dash_bdr_grp_expand {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#dash_bdr_grp_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#Dashboard_side_lbl_pc {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#dashboard_Icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#salary_grp_expand {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#salary_grp_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#salary_side_lbl_pc {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#salary_Icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#offering_grp_expand {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#offering_grp_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#Offerings_side_lbl_pc {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#offerings_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#connection_grp_expand {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#connection_grp_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#My_Connection_side_lbl_pc {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#my_connections_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 6px;
    overflow: visible;
}

#com_grp_expand {
    cursor: pointer;
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#com_grp_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#Communities_side_lbl_pc {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}

#community_icon_I_1 {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#offering_grp_expand_ {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#offering_grp_expand_:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

#Offerings_side_lbl_pc_ {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#offerings_icon_I_1_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#setup_expand {
    position: relative;
    width: 195px;
    height: 45px;
    left: 0px;
    overflow: visible;
}

#setup_expand:hover {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}

.setup_side_lbl {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

.setup_icon_ext {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}

#offering_grp_expand_ba {
    cursor: pointer;
    position: relative;
    width: 114px;
    height: 30px;
    left: 0px;
    overflow: visible;
}

#Offerings_side_lbl_pc_ba {
    left: 51px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(4, 4, 4, 1);
}

#logout_icon_I {
    position: relative;
    width: 30px;
    height: 30px;
    left: 7px;
    overflow: visible;
}

.active_page_e {
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 1);
}



/* Animation */
/* @keyframes slideInLeft {
    from {
      transform: translate3d(-0.5%, 0, 0);
      visibility: visible;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .slideInLeft {
    animation: slideInLeft 1s;
  } */

@keyframes slideInLeft {
    0% {
        transform: translate(-100px);
        position: absolute;
        z-index: 10;
    }

    100% {
        transform: translate(-0px);
        position: absolute;
        z-index: 10;
    }
}

.slideInLeft {
    animation: slideInLeft 0.4s linear both;
}