#Scroll_eexpense_card_invoice_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: 168px;
    left: 0px;
    top: 72px;
    overflow: hidden;
    overflow-y: scroll;
    text-align: center;
}
#expense_anakytics_grp_invoice_mob {
    position: absolute;
    width: 165px;
    height: 44px;
    left: 3px;
    top: 6px;
    overflow: visible;
}
#card_1_bdr_expense_analytics_invoice_mob {
    fill: rgba(255,255,255,1);
}
.card_1_bdr_expense_analytics_invoice_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 62px;
    left: 0px;
    top: 0px;
}
#Mohammed_Affan_lbl_invoice_mob {
    left: 16px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 150px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#n_000_lbl_invoice_mob {
    left: 16px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#status_expense_invoice_mob {
    fill: rgba(27,49,241,1);
}
.status_expense_invoice_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 3px;
    top: 7px;
}
#n_000_lbl_dash_pc_ef_invoice_mob {
    left: 75px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 87px;
    height: 15px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}