.mediaViewInfo {
    --web-view-name: tax pop up 2;
    --web-view-id: tax_pop_up_2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: tax_pop_up_2;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

.addtax_layout {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

#tax_pop_up_2 {
    position: absolute;
    width: 397px;
    height: 730px;
    overflow: hidden;
    --web-view-name: tax pop up 2;
    --web-view-id: tax_pop_up_2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#bg_design_of_pop_up_tax {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 378px;
    height: 720px;
    left: 12px;
    top: 0px;
    overflow: visible;
}

#tax_auth_lbl_pop_up_tax {
    left: 34.092px;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgb(0, 0, 0);
}

#tax_auth_innput_pop_up_tax {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.tax_auth_innput_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 157.22px;
    height: 40px;
    left: 34.092px;
    top: 97px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#interest_paid_pop_up_tax {
    left: 211.687px;
    top: 220.991px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#interest_paid_inpit_pop_up_tax {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.interest_paid_inpit_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 157.417px;
    height: 40px;
    left: 211.687px;
    top: 242.991px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#tax_amount_paid_lbl_pop_up_tax {
    left: 211.79px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#tax_amount_paid_inputpop_up_ta {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.tax_amount_paid_inputpop_up_ta {
    position: absolute;
    overflow: visible;
    width: 157.315px;
    height: 40px;
    left: 211.79px;
    top: 170px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#fine_paid_lbl_pop_up_tax {
    left: 33.895px;
    top: 220.982px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#fine_paid_input_pop_up_tax {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.fine_paid_input_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 157.417px;
    height: 40px;
    left: 33.895px;
    top: 242.982px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#remark_tax_pop_up_tax {
    left: 34.092px;
    top: 294px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#remark_input_pop_up_tax {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.remark_input_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 335.012px;
    height: 84px;
    left: 34.092px;
    top: 316px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#tax_btn_grp_pop_up_tax:hover {
    top: 640px;
}

#tax_btn_grp_pop_up_tax {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 137px;
    top: 645px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}

#passs_bdr_btn_pop_up_tax {
    fill: rgba(252,135,135,1);
}

.passs_bdr_btn_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}

#add_lbl_pop_up_tax {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#close_tax_pop_up_tax {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 348px;
    top: 13px;
    overflow: visible;
}

#due_date_lbl_pop_up_tax_1 {
    left: 34.092px;
    top: 147px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#due_date_pop_up_tax_date_1 {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.due_date_pop_up_tax_date_1 {
    position: absolute;
    overflow: visible;
    width: 157.315px;
    height: 40px;
    left: 34.092px;
    top: 170px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#add_tax_lbl_pop_up_tax {
    left: 163px;
    top: 14px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
}

#reg_no_lbl_pop_up_tax {
    left: 211.5px;
    top: 74px;
    position: absolute;
    overflow: visible;
    width: 156px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#redistration_n0_input_pop_up_t {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.redistration_n0_input_pop_up_t {
    overflow: visible;
    position: absolute;
    width: 157.254px;
    height: 40px;
    left: 211.5px;
    top: 97px;
    transform: matrix(1, 0, 0, 1, 0, 0);
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#status_pop_up_tax {
    left: 211.79px;
    top: 411px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

.statusinput_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 157.417px;
    height: 40px;
    left: 211.895px;
    top: 432px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#approved_by_paid_lbl_pop_up_ta {
    left: 33.895px;
    top: 410px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#approved_by_input_pop_up_tax {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.approved_by_input_pop_up_tax {
    position: absolute;
    overflow: visible;
    width: 157.417px;
    height: 40px;
    left: 33.895px;
    top: 432px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#paid_through_paid_lbl_pop_up_bi {
    left: 33.895px;
    top: 485px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#paid_through_input_pop_up_bill {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.paid_through_input_pop_up_bill {
    position: absolute;
    overflow: visible;
    width: 335px;
    height: 40px;
    left: 33.895px;
    top: 510px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}



#paid_name_paid_lbl_pop_up_bi {
    left: 33.895px;
    top: 565px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#paid_name_input_pop_up_bill {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.paid_name_input_pop_up_bill {
    position: absolute;
    overflow: visible;
    width: 335px;
    height: 40px;
    left: 33.895px;
    top: 510px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}

#paid_on_paid_lbl_pop_up_bi {
    left: 211.5px;
    top: 565px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#paid_on_input_pop_up_bill {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.paid_on_input_pop_up_bill {
    position: absolute;
    overflow: visible;
    width: 157.5px;
    height: 40px;
    left: 211.5px;
    top: 590px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}


#paid_by_paid_lbl_pop_up_bi {
    left: 211.5px;
    top: 565px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
}

#paid_by_input_pop_up_bill {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.paid_by_input_pop_up_bill {
    position: absolute;
    overflow: visible;
    width: 157.5px;
    height: 40px;
    left: 33.895px;
    top: 590px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}