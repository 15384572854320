.mediaViewInfo {
    --web-view-name: pop up add staff;
    --web-view-id: pop_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_add_staff;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.staff_layout{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
#pop_up_add_staff {
    position: absolute;
    width: 388px;
    height: 563px;
    overflow: hidden;
    --web-view-name: pop up add staff;
    --web-view-id: pop_up_add_staff;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Add_staff_popup_bg_over_all {
    position: absolute;
    width: 313px;
    height: 519px;
    left: 38px;
    top: 22px;
    overflow: visible;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#staff_id_lbl_Add_staff_popup {
    left: 54px;
    top: 145.52px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#staff_ID_Add_staff_popup {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_ID_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 282px;
    height: 40px;
    left: 54px;
    top: 166.044px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#staff_name_lbl_Add_staff_popup {
    left: 54px;
    top: 75.042px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#staff_name_Add_staff_popup {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_name_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 282.123px;
    height: 40px;
    left: 54px;
    top: 95.566px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Icon_ionic-mpop__Add_staff_pop {
    fill: rgba(28,26,26,1);
}
.Icon_ionic-mpop__Add_staff_pop {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: matrix(1,0,0,1,316,40) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#btn_grp_pop_pop__Add_staff_pop:hover {
    left: 113px;
    top: 483.379px;
}
#btn_grp_pop_pop__Add_staff_pop {
    position: absolute;
    width: 167px;
    height: 44px;
    left: 108px;
    top: 477px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Add_staff_popup_btn {
    fill: rgba(252,135,135,1);
}
.Add_staff_popup_btn {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_staff_popup_invoice {
    left: 49px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_staff_Add_staff_popup {
    left: 147px;
    top: 33px;
    position: absolute;
    overflow: visible;
    width: 90px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#joining_date_lbl_Add_staff_pop {
    left: 201.123px;
    top: 217.48px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#joining_date_Add_staff_popup {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.joining_date_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 135px;
    height: 40px;
    left: 201px;
    top: 237px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#DOB_lbl_Add_staff_popup {
    left: 53.708px;
    top: 216px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#DOB_input_Add_staff_popup {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.DOB_input_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 135.292px;
    height: 40px;
    left: 53.708px;
    top: 237px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Pan_Input_Add_staff_popup {
    left: 54px;
    top: 287px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Pan_input_Add_staff_popup {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pan_input_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 135px;
    height: 40px;
    left: 54px;
    top: 307px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
.active_box_Add_staff_popup {
    position: absolute;
    overflow: visible;
    width: 29px;
    height: 14px;
    left: 253px;
    top: 436px;
}
#active_lbl_Add_staff_popup {
    left: 296px;
    top: 433px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#staff_type_input_Add_staff_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_type_input_Add_staff_pop {
    position: absolute;
    overflow: visible;
    width: 135px;
    height: 40px;
    left: 201px;
    top: 307px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#staff_type_lbl_Add_staff_popup {
    left: 201px;
    top: 287px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_on_lbl_pop_up_freq {
    left: 200.529px;
    top: 359.954px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_on_input_pop_up_day {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_up_day {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 40px;
    left: 200.529px;
    top: 379.954px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#fre_lbl_pop_up_frequecy {
    left: 53.708px;
    top: 356.963px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#freq_input_pop_up_staff {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.freq_input_pop_up_staff {
    position: absolute;
    overflow: visible;
    width: 135.292px;
    height: 40px;
    left: 53.708px;
    top: 379.963px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_input_pop_up_staff {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_up_staff {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 40px;
    left: 269.154px;
    top: 379.928px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_lbl_pop_up_staff {
    left: 269.365px;
    top: 359.954px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
