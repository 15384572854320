.mediaViewInfo {
    --web-view-name: mob menu page;
    --web-view-id: mob_menu_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: mob_menu_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#mob_menu_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    overflow: hidden;
    --web-view-name: mob menu page;
    --web-view-id: mob_menu_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#mob_menu_overall_grp {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    overflow: visible;
}
#mob_menu_grp {
    position: absolute;
    display: flex;
    justify-content: center;
    width: calc(100% - 53%);
    height: 100%;
    left: 0px;
    overflow: visible;
}
#green_bg_menu_mob {
    fill: rgba(235, 56, 61, 1);
}
.green_bg_menu_mob {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#all_menu_details_mob {
    position: absolute;
    width: calc(100% - 10%);
    height: calc(100% - 35%);
    top: 207px;
    overflow: hidden;
    display: grid;
    justify-content: center;
}
#salary_grp_mob {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;  
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#salary_mob_side_lbl {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#salaery_Icon_I_1 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#dashboard_grp_mob{
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#Dashboard_side_lbl_mob {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#dashboard_Icon_I_1_mob {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#tax_mob_grp_expand {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#tax_mob_lbl_pc {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#tax_mob_icon_I_1 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#bill_mob_grp_expand {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#mob_bill_side_lbl_pc {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#bill_mob_icon_I_1 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#purchase_grp_expand {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#ourchase_mob_side_lbl_pc {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#purchase_mob_icon_I_1 {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#invoice_grp_expand {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#invoice_side_lbl_mob {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#invoice_icon_I_1_mob {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#logout_grp_expand {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(20, 122, 224, 0.164);
    border-radius: 8px;
}
#logout_mob_side_lbl_pc {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
#logout_icon_I_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
.reset_pass_mob{
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(246, 11, 31, 0.169);
    border-radius: 8px;
}
#reset_pass_lbl_mob{
    right: calc(100% - 98%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width:111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
.reset_pass_mob_icon{
    position: absolute;
    width: 25px;
    height: 25px;
    right: calc(100% - 23%);
    top: 9px;
    overflow: visible;
}
#setup_grp_expand_mob {
    position: relative;
    width: 155px;
    height: 41px;
    left: 0px;
    overflow: visible;
    background-color: rgba(12, 13, 14, 0.119);
    border-radius: 8px;
}
#setup_lbl_mob {
    right: calc(100% - 77%);
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(4,4,4,1);
}
.setup_icon_ext_mob {
    position: absolute;
    width: 24px;
    height: 24px;
    right: calc(100% - 34%);
    top: 9px;
    overflow: visible;
}
#menu_name_pc_mob {
    top: 117px;
    position: absolute;
    overflow: visible;
    width: calc(100% - 20%);
    height: 19px;
    text-align: center;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pfp_grp_menu_mob {
    position: absolute;
    width: 57px;
    height: 57px;
    top: 50px;
    overflow: visible;
}
#pfp_bdr_menu_mob {
    fill: rgba(255,255,255,1);
}
.pfp_bdr_menu_mob {
    position: absolute;
    overflow: visible;
    width: 57px;
    height: 57px;
    left: 0px;
    top: 0px;
}
#pfp_img_menu_mob {
    position: absolute;
    width: 54px;
    height: 54px;
    left: 2px;
    top: 2px;
    overflow: hidden;
    border-radius: 50%;
}
.blur_img_bd {
    position: absolute;
    overflow: visible;
    width: calc(100% - 47%);
    height: 100%;
    right: 0px;
    top: 0px;
    background-color: rgba(11, 11, 11, 0.429);
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation: fadeIn 1s;
  }

