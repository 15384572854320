.mediaViewInfo {
    --web-view-name: tax page;
    --web-view-id: tax_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: tax_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#tax_page {
    width: 100vw;
    height: 100vh;    
    z-index: -10;
    overflow: hidden;
    --web-view-name: tax page;
    --web-view-id: tax_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Add_Tax_Authority_bg_overall {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    left: 0px;
    top: 0px;
    overflow: visible;
}
