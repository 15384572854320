.mediaViewInfo {
	--web-view-name: small nav invoice;
	--web-view-id: small_nav_invoice;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
:root {
	--web-view-ids: small_nav_invoice;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
#small_nav_invoice {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	--web-view-name: small nav invoice;
	--web-view-id: small_nav_invoice;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
#n_2188678_4907157 {
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0px;
	top: 0px;
	overflow: visible;
	z-index: -10;
}
