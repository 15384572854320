.mediaViewInfo {
    --web-view-name: dashboard;
    --web-view-id: dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: dashboard;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.overall_dashboard {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: dashboard;
    --web-view-id: dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#dash_pc_bg_overall {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
.dashboard{
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    left: 185px;
    min-width: 1292px;
    min-height: 613px;
    overflow: visible;
}
#Welcome_Mohammed_Affan__lbl_da {
    top: 49px;
    position: relative;
    width: 50%;
    height: 27px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}

#total_revenue_grp_dash_pc {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 242px;
    overflow: visible;
}
#over_all_card_bdr_revenbue_das {
    fill: rgba(255,255,255,1);
}
.over_all_card_bdr_revenbue_das {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#top_img_total_revenue_grp {
    position: absolute;
    width: 199px;
    height: 30px;
    left: 13px;
    top: 7px;
    overflow: visible;
}
#Total_Revenue_lbl_dash_pc {
    left: 0px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#bdr_img_revenue_dash_pc {
    fill: rgba(209,255,229,1);
}
.bdr_img_revenue_dash_pc {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 169px;
    top: 0px;
}
#revenue_img_dash_pc {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 172px;
    top: 3px;
    overflow: visible;
}
#Column_Chart1top {
    position: absolute;
    width: 104px;
    height: 48.001px;
    left: 108.47px;
    top: 64px;
    overflow: visible;
}
#graph_2_45 {
    fill: rgba(31,120,180,1);
}
.graph_2_45 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 38.648px;
}
#graph_2_12 {
    fill: rgba(31,120,180,1);
}
.graph_2_12 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.936px;
    left: 9.043px;
    top: 23.064px;
}
#graph_2_0 {
    fill: rgba(31,120,180,1);
}
.graph_2_0 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 26.182px;
}
#graph_2_9 {
    fill: rgba(31,120,180,1);
}
.graph_2_9 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 38.648px;
}
#graph_2_8 {
    fill: rgba(31,120,180,1);
}
.graph_2_8 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 27.43px;
}
#graph_2_7 {
    fill: rgba(31,120,180,1);
}
.graph_2_7 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 13.715px;
}
#graph_2_6 {
    fill: rgba(31,120,180,1);
}
.graph_2_6 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 29.299px;
}
#graph_2_5 {
    fill: rgba(31,120,180,1);
}
.graph_2_5 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 19.949px;
}
#graph_2_4 {
    fill: rgba(31,120,180,1);
}
.graph_2_4 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.402px;
    left: 72.348px;
    top: 10.598px;
}
#graph_2_3 {
    fill: rgba(31,120,180,1);
}
.graph_2_3 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 13.715px;
}
#graph_2_2 {
    fill: rgba(31,120,180,1);
}
.graph_2_2 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 20.57px;
}
#graph_2_1 {
    fill: rgba(31,120,180,1);
}
.graph_2_1 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 48px;
    left: 99.479px;
    top: 0px;
}
#Column_Chart1_shadow {
    opacity: 0.1;
    position: absolute;
    width: 104px;
    height: 37.403px;
    left: 108.47px;
    top: 94.054px;
    overflow: visible;
}
#graph_1_21 {
    fill: rgba(31,120,180,1);
}
.graph_1_21 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 17.948px;
}
#graph_1_1 {
    fill: rgba(31,120,180,1);
}
.graph_1_1 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.936px;
    left: 9.043px;
    top: 6.234px;
}
#graph_1_0 {
    fill: rgba(31,120,180,1);
}
.graph_1_0 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 7.793px;
}
#graph_1_9 {
    fill: rgba(31,120,180,1);
}
.graph_1_9 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 17.948px;
}
#graph_1_8 {
    fill: rgba(31,120,180,1);
}
.graph_1_8 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 8.416px;
}
#graph_1_7 {
    fill: rgba(31,120,180,1);
}
.graph_1_7 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 1.559px;
}
#graph_1_6 {
    fill: rgba(31,120,180,1);
}
.graph_1_6 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 17.947px;
}
#graph_1_5 {
    fill: rgba(31,120,180,1);
}
.graph_1_5 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 4.676px;
}
#graph_1_4 {
    fill: rgba(31,120,180,1);
}
.graph_1_4 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.402px;
    left: 72.348px;
    top: 0px;
}
#graph_1_3 {
    fill: rgba(31,120,180,1);
}
.graph_1_3 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 1.559px;
}
#graph_1_2 {
    fill: rgba(31,120,180,1);
}
.graph_1_2 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 4.987px;
}
#graph_1_1_dw {
    fill: rgba(31,120,180,1);
}
.graph_1_1_dw {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 17.897px;
    left: 99.479px;
    top: 17.947px;
}
#underline_revenue_dash_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.underline_revenue_dash_pc {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 13px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_lbl_revenue_dash_pc {
    left: 13px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 9px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_2345_lbl_revenure_dash_pc {
    left: 23px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n__Jan_2023_-_1_Jan_2024_date_ {
    left: 13px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Icon_awesome-arrow-up_dash_pc_minus{
    fill: rgba(238,119,119,1);
}
.Icon_awesome-arrow-up_dash_pc_minus {
    overflow: visible;
    position: absolute;
    width: 13.411px;
    height: 13.762px;
    transform: translate(0.47px, 0px) matrix(1,0,0,1,12.5307,105.0008) rotate(180deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#Icon_awesome-arrow-up_dash_pc_plus{
    fill: #2ECC71;
}
.Icon_awesome-arrow-up_dash_pc_plus{
    overflow: visible;
    position: absolute;
    width: 13.411px;
    height: 13.762px;
    transform: translate(0.47px, 0px) matrix(1,0,0,1,12.5307,105.0008);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#n_2_ups_and_down_ {
    left: 28px;
    top: 102px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(238,119,119,1);
}
#total_expense_card_1_grp_dash_ {
    position: absolute;
    width: 225px;
    height: 132px;
    overflow: visible;
}
#total_expense_card_bg_dash_pc {
    fill: rgba(255,255,255,1);
}
.total_expense_card_bg_dash_pc {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#Total_Expense_lbl_dash_pc {
    left: 13px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#card_grp_img_dash_pc {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 182px;
    top: 7px;
    overflow: visible;
}
#bdr_total_expense_img_dash_pc {
    fill: rgba(255,204,204,1);
}
.bdr_total_expense_img_dash_pc {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#budget_img_dash_pc {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#Column_Chart1_upper_total_grp {
    position: absolute;
    width: 104px;
    height: 48.001px;
    left: 108px;
    top: 64px;
    overflow: visible;
}
#top_graph_12 {
    fill: rgba(31,120,180,1);
}
.top_graph_12 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 38.648px;
}
#top_graph_11 {
    fill: rgba(31,120,180,1);
}
.top_graph_11 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.936px;
    left: 9.043px;
    top: 23.064px;
}
#top_graph_0 {
    fill: rgba(31,120,180,1);
}
.top_graph_0 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 26.182px;
}
#top_graph_9 {
    fill: rgba(31,120,180,1);
}
.top_graph_9 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 38.648px;
}
#top_graph_8 {
    fill: rgba(31,120,180,1);
}
.top_graph_8 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 27.43px;
}
#top_graph_7 {
    fill: rgba(31,120,180,1);
}
.top_graph_7 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 13.715px;
}
#top_graph_6 {
    fill: rgba(31,120,180,1);
}
.top_graph_6 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 29.299px;
}
#top_graph_5 {
    fill: rgba(31,120,180,1);
}
.top_graph_5 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 19.949px;
}
#top_graph_4 {
    fill: rgba(31,120,180,1);
}
.top_graph_4 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.402px;
    left: 72.348px;
    top: 10.598px;
}
#top_graph_3 {
    fill: rgba(31,120,180,1);
}
.top_graph_3 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 13.715px;
}
#top_graph_2 {
    fill: rgba(31,120,180,1);
}
.top_graph_2 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 20.57px;
}
#top_graph_1 {
    fill: rgba(31,120,180,1);
}
.top_graph_1 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 48px;
    left: 99.479px;
    top: 0px;
}
#Column_Chart1_total_expense_gr {
    opacity: 0.1;
    position: absolute;
    width: 104px;
    height: 37.403px;
    left: 108px;
    top: 94.054px;
    overflow: visible;
}
#shadow_12 {
    fill: rgba(31,120,180,1);
}
.shadow_12 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 17.948px;
}
#shadow_11 {
    fill: rgba(31,120,180,1);
}
.shadow_11 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.936px;
    left: 9.043px;
    top: 6.234px;
}
#shadow_0 {
    fill: rgba(31,120,180,1);
}
.shadow_0 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 7.793px;
}
#shadow_9 {
    fill: rgba(31,120,180,1);
}
.shadow_9 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 17.948px;
}
#shadow_8 {
    fill: rgba(31,120,180,1);
}
.shadow_8 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 8.416px;
}
#shadow_7 {
    fill: rgba(31,120,180,1);
}
.shadow_7 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 1.559px;
}
#shadow_6 {
    fill: rgba(31,120,180,1);
}
.shadow_6 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 17.947px;
}
#shadow_5 {
    fill: rgba(31,120,180,1);
}
.shadow_5 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 4.676px;
}
#shadow_4 {
    fill: rgba(31,120,180,1);
}
.shadow_4 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.402px;
    left: 72.348px;
    top: 0px;
}
#shadow_3 {
    fill: rgba(31,120,180,1);
}
.shadow_3 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 1.559px;
}
#shadow_2 {
    fill: rgba(31,120,180,1);
}
.shadow_2 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 4.987px;
}
#shadow_1 {
    fill: rgba(31,120,180,1);
}
.shadow_1 {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 17.897px;
    left: 99.479px;
    top: 17.947px;
}
#border_linedash_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_linedash_pc {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 13px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_lbl_dash_pc {
    left: 13px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 9px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_2345_money_dash_pc {
    left: 23px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n__Jan_2023_-_1_Jan_2024_date__e {
    left: 13px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.donut_chart_stock_analytics{
    position: relative;
    display: flex;
    justify-content: center;
    width: 215px;
    height: 300px;
}
#Stock_Analytics_lbl_dash_pc {
   
    position: absolute;
    overflow: visible;
    width: 143px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#Expense_lbl_dash_pc {
    position: absolute;
    width: 78px;
    right: 343px;
    top: calc(100% - 490px);
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
.donut_chart_expence{
    position: relative;
    width: 215px;
    height: 300px;
    display: flex;
    justify-content: center;
    margin-right: 20px;
}
#Expense_lbl_dash_pc_All {
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#Rectangle_109 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_109 {
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 35px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#expense_detail_grp_All {
    position: absolute;
    width: 184px;
    height: 80px;
    bottom: 0px;
    overflow: visible;
}
#expense_detail_grp {
    position: absolute;
    width: 184px;
    height: 80px;
    right: 270px;
    top: calc(100% - 280px);
}
#Unassigned_lbl_client_dash {
    left: 15px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash {
    fill: #c088d6;
}
.unassigned_grey_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 6px;
}
#Assigned_lbl_client_dash {
    left: 119px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#yellow_assigned_client_dash {
    fill: #fc8787;
}
.yellow_assigned_client_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 104px;
    top: 6px;
}
#Work_in_progress_client_dash {
    left: 119px;
    top: 60px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#blue_work_in_progress_dash {
    fill: rgba(255,36,36,1);
}
.blue_work_in_progress_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 104px;
    top: 66px;
}
#Completed_lbl_client_dash {
    left: 119px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#completed_greeen_dash {
    fill: rgba(106,169,255,1);
}
.completed_greeen_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 104px;
    top: 36px;
}
#Cancelled_lbl_client_dash {
    left: 15px;
    top: 60px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash {
    fill: rgba(153,153,153,1);
}
.red_cancelled_client_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 66px;
}
#On_Hold_lbl_client_dash {
    left: 15px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash {
    fill: rgba(255,153,0,1);
}
.orange_on_hold_dash {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 36px;
}
#stock_detail_color {
    position: absolute;
    width: 172px;
    height: 50px;
    bottom: 30px;
    overflow: visible;
}
#Unassigned_lbl_client_dash_mob_hf {
    left: 15px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash_mob_hg {
    fill: rgba(255,0,0,1);
}
.unassigned_grey_dash_mob_hg {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 6px;
}
#Cancelled_lbl_client_dash_mob_hh {
    left: 120px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash_mob_hi {
    fill: rgba(106,169,255,1);
}
.red_cancelled_client_dash_mob_hi {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 105px;
    top: 6px;
}
#On_Hold_lbl_client_dash_mob_hj {
    left: 15px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob_hk {
    fill: #fc8787;
}
.orange_on_hold_dash_mob_hk {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 36px;
}
#On_Hold_lbl_client_dash_mob_hl {
    left: 120px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob_hm {
    fill: rgba(153,153,153,1);
}
.orange_on_hold_dash_mob_hm {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 105px;
    top: 36px;
}
#income_loss_grp_dashboard {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 487px;
    overflow: visible;
}
#income_loss_card_grp {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#income_loss_card {
    fill: rgba(255,255,255,1);
}
.income_loss_card {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#Income_Loss_lbl_pc {
    left: 13px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#income_line_dashboard_pc {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.income_line_dashboard_pc {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 13px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_10000000_lbl_dashboard_pc {
    left: 0px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 226px;
    height: 47px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(0,0,0,1);
}
#money_income_bdr_dash {
    fill: rgba(209,255,229,1);
}
.money_income_bdr_dash {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 182px;
    top: 7px;
}
#money-bag_2_img_lbl_dashboard_ {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 185px;
    top: 10px;
    overflow: visible;
}
.scroll_top_3{
    position: absolute;
    top: 92px;
    width: calc(100% - 823.5px);
    height: 145px;
    min-width: 385px;
    overflow-x: scroll;
    overflow-y: hidden;
}

::-webkit-scrollbar {
    height: 3px;
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(252,135,135,1); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #78ebaa; 
  }


  .overall_chart{
    position: absolute;
    display: inline-flex;
    width: 452px;
    height: 302px;
    right: 305px;
    top: calc(100% - 327px);
  }
  #chartAll{
    position: absolute;
    width: 150px;
    height: 150px;
    top: 47px;
}
#chart1{
    position: absolute;
    width: 150px;
    height: 150px;
    right: -239px;
    top: -120px;
} 
#chart2{
    position: absolute;
    width: 150px;
    height: 150px;
    top: 47px;
}
