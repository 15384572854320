.mediaViewInfo {
    --web-view-name: rigth side;
    --web-view-id: rigth_side;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: rigth_side;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#rigth_side_tax_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 13;
    display: inline-flex;
    overflow: hidden;
    --web-view-name: rigth side;
    --web-view-id: rigth_side;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#all_grp_right_side_mob_tax {
    position: relative;
    width: calc(100% - 35%);
    height: 100%;
    top: 0px;
    overflow: visible;
}
#bg_ogf_side_part_mob_tax {
    fill: rgb(212, 245, 229);
}
.bg_ogf_side_part_mob_tax {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    top: 0px;
}
.chart_mob_right_tax {
    position: absolute;
    width: 100%;
    height: 150.004px;
    top: 41.11px;
    display: flex;
    justify-content: center;
}
#product_add_mob_tax {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 222px;
    overflow: visible;
}
#search_tax_right_mob{
    z-index: 10;
    position: absolute;
    width: 20px;
    height: 20px;
    left: calc(100% - 23%);
    top: 46px;
    overflow: visible;
}
#filter_tax_right_mob {
    fill: rgba(255,255,255,1);
}
.filter_tax_right_mob {
    position: absolute;
    overflow: visible;
    width: 94%;
    height: 38px;
    top: 38px;
    border-radius: 10px;
    padding-left: 10px;
}
#product_grp_with_add_mob_tax {
    position: absolute;
    width: 140px;
    height: 26px;
    top: 0px;
    overflow: visible;
}
#add_btn_tax_authority_mob_tax {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 0px;
    top: 0px;
    overflow: visible;
}
#add_bg_Add_Tax_Authority_mob_tax {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_Tax_Authority_mob_tax {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 26px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Add_Tax_Authorityplus__mob_tax {
    fill: rgba(0,0,0,1);
}
.Add_Tax_Authorityplus__mob_tax {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4.11px;
    transform: matrix(1,0,0,1,0,0);
}
#tax_authority_lbl_tax_page_mob_tax {
    left: 0px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Scroll_product_mob_tax {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 14%);
    top: 80px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_product_mob_tax::-webkit-scrollbar{
    display: none;
}
#tax_authority_card_1_tax_page_tax {
    position: absolute;
    width: calc(100% - 16%);
    height: 117px;
    left: calc(100% - 92%);
    top: 10px;
    overflow: visible;
}
#card_1_bdr_tax_authority_mob_tax {
    fill: transparent;
}
.card_1_bdr_tax_authority_mob_tax {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 117px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 20px );
    -webkit-backdrop-filter:  blur( 20px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Tax_Authority_lbl_tax_page_mob_dx_tax {
    left: 40px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: calc(100% - 30%);
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mob_tax_authority_tax {
    left: 40px;
    top: 53px;
    position: absolute;
    overflow: visible;
    width: calc(100% - 30%);
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
    text-align: center;
}
#State_tax_authority_mob_tax {
    left: 40px;
    top: 94px;
    position: absolute;
    overflow: visible;
    width: calc(100% - 30%);
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
    text-align: center;
}
.btw_tax_and_reg_mob_tax {
    overflow: visible;
    position: absolute;
    width: calc(100% - 7%);
    height: 2px;
    left: 4.5px;
    top: 40.5px;
    border-top: 1px dashed rgba(112,112,112,1);
}
.between_reg_and_state_line_mob_tax {
    overflow: visible;
    position: absolute;
    width: calc(100% - 7%);
    height: 1px;
    left: 4.5px;
    top: 82.5px;
    border-top: 1px dashed rgba(112,112,112,1);
}
#taxes_1__img_all_page_right_mo_tax {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 7px;
    overflow: visible;
}
#reg__img_all_page_right_mob_tax {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 49px;
    overflow: visible;
}
#location_img_all_page_right_mo_tax {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 91px;
    overflow: visible;
}
.blur_right_tax{
    position: relative;
    overflow: visible;
    width: calc(100% - 65%);
    height: 100%;
    top: 0px;
    z-index: 10;
    background-color: rgba(11, 11, 11, 0.429);
}

