.mediaViewInfo {
    --web-view-name: expense mob;
    --web-view-id: expense_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: expense_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#expense_mob {
    position: absolute;
    width: calc(100% - 8%);
    height: 251px;
    top: 492px;
    left: 10px;
    overflow: hidden;
    --web-view-name: expense mob;
    --web-view-id: expense_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_grp_expense_mob {
    position: absolute;
    width: 100%;
    height: 246px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#expense_analytics_over_all_bdr_mob {
    fill: rgba(255,255,255,1);
}
.expense_analytics_over_all_bdr_mob {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 254px;
    left: 0px;
    top: 0px;
}
#Expense_Analytics_lbl_dash_mob {
    left: 12px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#detail_SBTPI {
    position: absolute;
    width: calc(100% - 4%);
    height: 35px;
    left: 5px;
    top: 33px;
    display: inline-flex;
    overflow: visible;
}
#sla_expense_mob {
    position: relative;
    width: calc(100% - 79%);
    height: 35px;
    top: 0px;
    overflow: visible;
}
#salaries_dexpense_mob {
    fill: rgba(106,169,255,1);
}
.salaries_dexpense_mob {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 6px;
    left: 0px;
    top: 0px;
}
#Salaries_lbl_expense_mob {
    left: 14px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#salariesexpense_mob {
    fill: rgba(106,169,255,1);
}
.salariesexpense_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 8px;
}
#n_100000_salary_expense_mob {
    left: 0px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#bills_expense_mob {
    position: relative;
    width: calc(100% - 79%);
    height: 35px;
    top: 0px;
    overflow: visible;
}
#bills_bar_expense_mob {
    fill: #fc8787;
}
.bills_bar_expense_mob {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 6px;
    left: 0px;
    top: 0px;
}
#Bills_lbl_expense_mob {
    left: 14px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 18px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#bills_circle_expense_mob {
    fill: #fc8787;
}
.bills_circle_expense_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 2px;
    top: 8px;
}
#n_10000_bills_expense_mob {
    left: 2px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#taxes_expense_mob {
    position: relative;
    width: calc(100% - 79%);
    height: 35px;
    top: 0px;
    overflow: visible;
}
#taxes_bar_expense_mob {
    fill: rgba(186,106,255,1);
}
.taxes_bar_expense_mob {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 6px;
    left: 0px;
    top: 0px;
}
#Taxes_lvbl_expense_mob {
    left: 14px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 24px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#taxes_bdr_expense_mob {
    fill: rgba(186,106,255,1);
}
.taxes_bdr_expense_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 2px;
    top: 8px;
}
#n_1000000_taxes_expense_mob {
    left: 2px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#invoice_expense_mob {
    position: relative;
    width: calc(100% - 79%);
    height: 35px;
    top: 0px;
    overflow: visible;
}
#invoice_bdr_progress_bar_expen {
    fill: rgba(197,197,197,1);
}
.invoice_bdr_progress_bar_expen {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 6px;
    left: 0px;
    top: 0px;
}
#Invoices_lbl_expense_mob {
    left: 15px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#invoice_color_circleexpense_mo {
    fill: rgba(197,197,197,1);
}
.invoice_color_circleexpense_mo {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 3px;
    top: 8px;
}
#n_10000_invoice_lbl_expense_mo {
    left: 3px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#purchase_expense_mob {
    position: relative;
    width: calc(100% - 79%);
    height: 35px;
    top: 0px;
    overflow: visible;
}
#purchase_bdr_expense_mob {
    fill: rgba(252,135,135,1);
}
.purchase_bdr_expense_mob {
    position: absolute;
    overflow: visible;
    width: 65px;
    height: 6px;
    left: 0px;
    top: 0px;
}
#Purchases_lv_expense_mob {
    left: 14px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#purchase_circe_expense_mob {
    fill: rgba(252,135,135,1);
}
.purchase_circe_expense_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 2px;
    top: 8px;
}
#n_10000_purchase_expense_mob {
    left: 2px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: rgba(0,0,0,1);
}
#add_btn_all_mob {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    left: calc(100% - 11%);
    top: 5px;
    overflow: visible;
}
#add_bg_all_mob {
    fill: rgba(252,135,135,1);
}
.add_bg_all_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 22px;
    height: 22px;
    left: 0px;
    top: 0px;
}
#Add_all_mob {
    fill: rgba(0,0,0,1);
}
.Add_all_mob {
    overflow: visible;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
