.mediaViewInfo {
    --web-view-name: only top;
    --web-view-id: only_top;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: only_top;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#only_top_settings {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: only top;
    --web-view-id: only_top;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_bg_design_mob_settings {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}