#Scroll_stock_overview {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 82px);
    top: 70px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_stock_overview::-webkit-scrollbar{
    display: none;
}
#card1_grp_lbl_dashboard_pc {
    position: absolute;
    width: 182px;
    height: 93px;
    left: 20px;
    top: 6px;
    overflow: visible;
}
#inside_bg_card1lbl_dashboard_p {
    position: absolute;
    width: 182px;
    height: 93px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#n_50_card1_lbl_dashboard_pc {
    left: 6px;
    top: 64px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#lenovo_lbl_dashboard_pc {
    left: 6.023px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#Amount_card1_lbl_dashboard_pc {
    left: 6px;
    top: 44px;
    position: absolute;
    overflow: visible;
    width: 47px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#n__Days_lbl_dashboard_pc {
    left: 106.023px;
    top: 64px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#expir_lbl_dashboard_pc {
    left: 106.023px;
    top: 44px;
    position: absolute;
    overflow: visible;
    width: 47px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#name_lbl_dashboard_pc {
    left: 6px;
    top: 25px;
    position: absolute;
    overflow: visible;
    width: 169px;
    height: 17px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}