.mediaViewInfo {
    --web-view-name: new merchant page;
    --web-view-id: new_merchant_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: new_merchant_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.merchant_layout{
    display: flex;
    justify-content: center;
    margin-top: 15px;
}
#new_merchant_page {
    position: absolute;
    width: 389px;
    height: 768px;
    overflow: hidden;
    --web-view-name: new merchant page;
    --web-view-id: new_merchant_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_up_bg_deisgn_merchant {
    position: absolute;
    width: 313px;
    height: 658px;
    left: 38px;
    top: 35px;
    overflow: visible;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Icon_ionic-md-pop_up_merchant {
    fill: rgba(28,26,26,1);
}
.Icon_ionic-md-pop_up_merchant {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: matrix(1,0,0,1,319,49) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#btn_grp_merchantpop_up:hover {
    left: 115px;
    top: 628px;
}
#btn_grp_merchantpop_up {
    position: absolute;
    width: 167px;
    height: 44px;
    left: 108px;
    top: 618px;
    overflow: visible;
    transition: all .3s ease-out;
}
#pop_up_invoice_btn_merchant {
    fill: rgba(252,135,135,1);
}
.pop_up_invoice_btn_merchant {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_merchantpop_up_invoice {
    left: 34px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_merchantlbl_pfp_client_pop {
    left: 123px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 144px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#add_merchant_scroll {
    mix-blend-mode: normal;
    position: absolute;
    width: 313.447px;
    height: 508.663px;
    left: 38.137px;
    top: 83.504px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_grp_prof_merchantpop_up {
    position: absolute;
    width: 280.583px;
    height: 54.48px;
    left: 16.863px;
    top: 0px;
    overflow: visible;
}
#name_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#name_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 280.583px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Address_grp_prof_merchantpop_u {
    position: absolute;
    width: 280.727px;
    height: 101.638px;
    left: 16px;
    top: 264.174px;
    overflow: visible;
}
#address_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_input_pfp_merchantpop_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_pfp_merchantpop_ {
    position: absolute;
    overflow: visible;
    width: 280.727px;
    height: 81.422px;
    left: 0px;
    top: 20.216px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#city_grp_merchantpop_up {
    position: absolute;
    width: 131.876px;
    height: 54.478px;
    left: 16px;
    top: 377.999px;
    overflow: visible;
}
#state_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 131.876px;
    height: 33.954px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#state_grp_merchantpop_up {
    position: absolute;
    width: 131.876px;
    height: 54.478px;
    left: 162.5px;
    top: 378.589px;
    overflow: visible;
}
#state_lbl_pfp_merchantpop_up_dh {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_input_pfp_merchantpop_up_di {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp_merchantpop_up_di {
    position: absolute;
    overflow: visible;
    width: 131.876px;
    height: 33.954px;
    left: 0px;
    top: 20.523px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Pin_grp_prof_merchantpop_up {
    position: absolute;
    width: 131.876px;
    height: 54.48px;
    left: 164.376px;
    top: 199.511px;
    overflow: visible;
}
#Pin_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Pin_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 131.876px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#PAN_prof_merchantpop_up {
    position: absolute;
    width: 132.74px;
    height: 53.48px;
    left: 16px;
    top: 200.511px;
    overflow: visible;
}
#PAN_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#PAN_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.PAN_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 132.739px;
    height: 33.956px;
    left: 0px;
    top: 19.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#GSTIN_grp_merchantpop_up {
    position: absolute;
    width: 280.583px;
    height: 54.478px;
    left: 16px;
    top: 134.513px;
    overflow: visible;
}
#GSTIN_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#gstin_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gstin_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 280.583px;
    height: 33.954px;
    left: 0px;
    top: 20.523px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#information_grp_merchantpop_up {
    position: absolute;
    width: 280.727px;
    height: 101.633px;
    left: 16px;
    top: 507.997px;
    overflow: visible;
}
#company_lbl_merchantpop_uppop_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#company_imerchantpop_up_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.company_imerchantpop_up_pop_up {
    position: absolute;
    overflow: visible;
    width: 280.727px;
    height: 81.419px;
    left: 0px;
    top: 20.213px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#active_btn_merchantpop_up {
    position: absolute;
    width: 85.903px;
    height: 20px;
    left: 113px;
    top: 624.663px;
    overflow: visible;
}
#switch_area_pop_up_invoice_mer {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_area_pop_up_invoice_mer {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 16px;
    left: 0px;
    top: 2px;
}
#company_lbl_pfp_pop_up_invoice {
    left: 45.903px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#emaoil__grp_prof_merchantpop_u {
    position: absolute;
    width: 132.667px;
    height: 54.48px;
    left: 16.072px;
    top: 67.257px;
    overflow: visible;
}
#email_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 132.667px;
    height: 33.956px;
    left: 0px;
    top: 20.525px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#mob_grp_prof_merchantpop_up {
    position: absolute;
    width: 132.667px;
    height: 54.48px;
    left: 164.779px;
    top: 67.257px;
    overflow: visible;
}
#mob_lbl_pfp_merchantpop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mob_input_pfp_merchantpop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mob_input_pfp_merchantpop_up {
    position: absolute;
    overflow: visible;
    width: 132.667px;
    height: 33.956px;
    left: 0px;
    top: 20.525px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#frequency_grp_prof_merchantpop {
    position: absolute;
    width: 131.876px;
    height: 53.524px;
    left: 16.5px;
    top: 443.587px;
    overflow: visible;
}
#freq_lbl_pfp_merchantpop_up {
    left: -0.001px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#frequency_input_pfp_merchantpo {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.frequency_input_pfp_merchantpo {
    position: absolute;
    overflow: visible;
    width: 131.876px;
    height: 33px;
    left: -0.001px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#due_on_lbl_pop_up_fpurchase_me {
    left: 162.5px;
    top: 444.587px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_on_input_pop_purchase_merc {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_purchase_merc {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 33px;
    left: 162.5px;
    top: 464.713px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_input_pop_up_purchase_m {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_up_purchase_m {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 33px;
    left: 231.124px;
    top: 464.689px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_lbl_pop_up_purchase_mer {
    left: 231.335px;
    top: 444.587px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
