.tax_overall_container{
    position: absolute;
    width: calc(100% - 547px);
    height: calc(100% - 90px);
    left: 182px;
    top: 50px;
}
#top_menu_bdr_upcome_grp_pc {
    position: absolute;
    width: calc(100% - 22px);
    top: 50px;
    height: 41px;
    overflow: visible;
}
#top_menu_bdr_upcome_bdr {
    fill: rgba(241,241,241,1);
}
.top_menu_bdr_upcome_bdr {
    position: absolute;
    overflow: visible;
    width: calc(100% - 22px);
    height: 30px;
    left: 0px;
    top: 0px;
}
.tax_heading{
    position: absolute;
    width: 100%;
    height: 49px;
    display: inline-flex;
}
#Registration_top_menu_bdr_upco_pc {
    left: 0px;
    top: 5px;
    position: relative;
    overflow: visible;
    width: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Interest_top_menu_bdr_upcome_pc {
    top: 5px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Tax_Authority_top_menu_bdr_upc {
    top: 5px;
    position: relative;
    overflow: hidden;
    left: 0px;
    width: 200px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Due_Date_top_menu_bdr_upcome_pc {
    top: 5px;
    position: relative;
    overflow: hidden;
    width: 135px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Due_Date_tax_menu_bdr_upcome_pc {
    top: 5px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Tax_Amount_top_menu_bdr_upcome {
    top: 5px;
    position: relative;
    overflow: visible;
    width: 135px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Fine_top_menu_bdr_upcome_pc {
    top: 5px;
    position: relative;
    overflow: visible;
    width: 135px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Status_top_menu_bdr_upcome_pc {
    top: 5px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#upcoming_scroll_tax_page {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    top: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#upcoming_scroll_tax_page::-webkit-scrollbar{
    display: none;
}
#card_1upcoming_page {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 41px;
    left: 10px;
    top: -2px;
    overflow: visible;
}
#card_1_bg_upcoming_page {
    fill: rgba(255,255,255,1);
}
.card_1_bg_upcoming_page {
    filter: drop-shadow(0px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: calc(100% - 12px);
    height: 49px;
    left: 0px;
    top: 0px;
}
.tax_data{
    position: relative;
    width: 100%;
    height: 31px;
    display: inline-flex;
}
#name_taxes_upcoming_page {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 200px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#from_date_taxes_upcoming_page {
    top: 11px;
    position: relative;
    width: 135px;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#from_date_taesx_upcoming_page {
    top: 11px;
    position: relative;
    width: 135px;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#amount_tax_upcoming_page {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#EID_tax_upcoming_page {
    top: 11px;
    left: 0px;
    position: relative;
    overflow: visible;
    width: 180px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#status_tax_upcoming_page {
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.status_word{
    width: 100px;
    text-align: center;
}
#to_date_upcomingtax_page {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#amount_tax_upcoming_page_cj {
    top: 11px;
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.status_tax{
    position: relative;
    width: 135px;
    height: 41px;
    top: 4px;
}
.to_pay_tax {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 38px;
}

#add_btn_tax {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 85%;
    overflow: visible;
}
#add_bg_Add_tax {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_tax {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_tax {
    fill: rgba(0,0,0,1);
}
.Add_tax {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#lbl_tax {
    left: 89%;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}