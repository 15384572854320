.mediaViewInfo {
    --web-view-name: chamge pass finance;
    --web-view-id: chamge_pass_finance;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: chamge_pass_finance;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.reset_layout{
    display: flex;
    justify-content: center;
    margin-top: 170px;
}
#chamge_pass_finance {
    position: absolute;
    width: 283px;
    height: 296px;
    border-radius: 10px;
    overflow: hidden;
    --web-view-name: chamge pass finance;
    --web-view-id: chamge_pass_finance;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_grp_change_pass {
    position: absolute;
    width: 283px;
    height: 301px;
    left: 0px;
    top: -5px;
    overflow: visible;
}
#top_bg_change_pass {
    position: absolute;
    width: 283px;
    height: 301px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#close_pup_op_change_passs {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 248px;
    top: 10px;
    overflow: visible;
}
#Change_Password_lbl_ {
    left: 72px;
    top: 13px;
    position: absolute;
    overflow: visible;
    width: 141px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#old_pass_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.old_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 14px;
    top: 65px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#new_pass_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.new_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 14px;
    top: 120px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#confirm_new_pass_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.confirm_new_pass_input {
    position: absolute;
    overflow: visible;
    width: 255px;
    height: 40px;
    left: 14px;
    top: 175px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#btn_change_password {
    position: absolute;
    width: 131px;
    height: 39px;
    left: 75px;
    top: 235px;
    overflow: visible;
}
#change_btn_ {
    fill: rgba(252,135,135,1);
}
.change_btn_ {
    position: absolute;
    overflow: visible;
    width: 131px;
    height: 39px;
    left: 0px;
    top: 0px;
}
#Change_lbl_ {
    left: 40px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 52px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}