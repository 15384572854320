.mediaViewInfo {
    --web-view-name: salary page;
    --web-view-id: salary_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: salary_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#invoice_page_mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    --web-view-name: salary page;
    --web-view-id: salary_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_product_page_invoice {
    position: absolute;
    width: calc(100% - 10.5%);
    height: 100%;
    left: 0px;
    top: 68px;
    overflow: visible;
}
#Scroll_salary_mob_invoice {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 118px);
    left: 0px;
    top: 22.685px;
    overflow: hidden;
    overflow-y: scroll;
    text-align: center;
}
#Scroll_salary_mob_invoice::-webkit-scrollbar{
    display: none;
}
#card_1_invoice_page_mob {
    position: absolute;
    width: 156px;
    height: 150px;
    left: 10px;
    top: 12px;
    overflow: visible;
}
#com_req_grp_card_1_invoice {
    position: absolute;
    width: 156px;
    height: 150px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#com_req_card_1_invoice {
    fill: rgba(255,255,255,1);
}
.com_req_card_1_invoice {
    position: absolute;
    overflow: visible;
    width: 156px;
    height: 150px;
    left: 0px;
    top: 0px;
    border-radius:  9px;
    background:  #e0e0e0;
    box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
}
.Rectangle_63_invoice {
    position: absolute;
    overflow: visible;
    width: 156px;
    height: 30px;
    left: 0px;
    top: 0.315px;
}
#name_invoice_upcoming_page {
    left: 0px;
    top: 5.315px;
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#due-date_invoice {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 40.315px;
    overflow: visible;
}
#from_date_invoice_upcoming_page {
    left: 43px;
    top: 45.315px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#salary_invoice {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 8px;
    top: 80.315px;
    overflow: visible;
}
#to_date_upcoming_page_invoice {
    left: 43px;
    top: 85.315px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#to_date_upcoming_page_dl_invoice {
    left: 0px;
    top: 120.315px;
    position: absolute;
    overflow: visible;
    width: 157px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#invoice_btn_grp_mob {
    position: absolute;
    width: calc(100% - 29px);
    height: 26px;
    right: calc(100% - 96%);
    top: 0px;
    overflow: visible;
    vertical-align: middle;
}
#add_btn_invoice_mob {
    position: absolute;
    width: 26px;
    height: 26px;
    right: 0px;
    overflow: visible;
}
#add_bg_Add_invoice_mob {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_invoice_mob {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 26px;
    height: 26px;
    left: 0px;
    top: 0px;
}
#Add_Tax_invoice_mob {
    fill: rgba(0,0,0,1);
}
.Add_Tax_invoice_mob {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 3.91px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#invoice_mob_lbl_tax_page_pc {
    top: 2px;
    right: 36px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}