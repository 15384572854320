.mediaViewInfo {
    --web-view-name: stock mpob;
    --web-view-id: stock_mpob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: stock_mpob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#stock_grp_mob_expired {
    position: absolute;
    width: calc(100% - 10%);
    height: 260px;
    top: 46px;
    overflow: visible;
}
#stock_overview_bdr_lbl_mob_expired {
    fill: rgba(255,255,255,1);
}
.stock_overview_bdr_lbl_mob_expired {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 268px;
    left: 0px;
    top: 0px;
}
#Stock_Overview_lbl_mob_expired {
    left: 12px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#dashboard_mob_expired_grp {
    position: absolute;
    width: 344px;
    height: 32px;
    top: 0px;
    overflow: visible;
}
#over_all_secter_bg_mob_expired {
    fill: rgba(255,255,255,1);
}
.over_all_secter_bg_mob_stock {
    filter: drop-shadow(5px 5px 2px rgba(203, 203, 203, 1));
    position: absolute;
    overflow: visible;
    width: 352px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#mark_about_hanger_mob_expired {
    fill: rgba(252,135,135,1);
}
.mark_about_hanger_mob_expired {
    position: absolute;
    overflow: visible;
    width: 93px;
    height: 32px;
    right: 0px;
    top: 0px;
}
#near_expiry_lbl_purchase_mob_expired {
    left: 15px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#in_use__lbl_purchase_mob_expired {
    left: 121px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#instock_lbl_purchase_mob_expired {
    left: 195px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#expired_lbl_purchase_mob_expired {
    left: 269px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}