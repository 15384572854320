#Scroll_Group_3_invoice {
    mix-blend-mode: normal;
    position: absolute;
    text-align: center;
    width: 99%;
    height: calc(100% - 95px);
    top: 90px;
    overflow-x: hidden;
    overflow-y: scroll;
}

#Scroll_Group_3_invoice::-webkit-scrollbar {
    display: none;
}

#expense_anakytics_grp_dash_pc {
    position: absolute;
    width: 342px;
    height: 56px;
    left: 8px;
    overflow: visible;
}

#card_1_bdr_expense_analytics {
    fill: rgba(255, 255, 255, 1);
}

.card_1_bdr_expense_analytics {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 360px;
    height: 74px;
    left: 0px;
    top: 0px;
}

#Mohammed_Affan_lbl_dash_pc_invoice {
    left: 38px;
    top: 3px;
    position: absolute;
    overflow: hidden;
    width: 238px;
    height: 22px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0, 0, 0, 1);
}

#n_000_lbl_dash_pc_invoice {
    left: 38px;
    top: 28px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

#pay_grp_dash_pc:hover {
    fill: rgba(119, 238, 170, 1);
}

#pay_grp_dash_pc {
    position: absolute;
    width: 71px;
    height: 24px;
    left: 267px;
    top: 26px;
    overflow: visible;
    transition: all .3s ease-out;
}

#Rectangle_86_f {
    fill: rgba(255, 255, 255, 1);
}

.Rectangle_86_f {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 89px;
    height: 42px;
    left: 0px;
    top: 0px;
}

#Pay {
    left: 2px;
    top: 2px;
    position: absolute;
    overflow: hidden;
    width: 70px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
}

#status_expense_ {
    fill: rgba(27, 49, 241, 1);
}

.status_expense_ {
    position: absolute;
    overflow: visible;
    width: 12px;
    height: 12px;
    left: 9px;
    top: 23px;
}

#n_000_lbl_dash_pc_f {
    left: 116px;
    top: 30px;
    position: absolute;
    overflow: visible;
    width: 102px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0, 0, 0, 1);
}