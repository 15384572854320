.nearexpiry_overall_container{
    position: absolute;
    width: calc(100% - 547px);
    height: calc(100% - 90px);
    left: 182px;
    top: 50px;
}
body::-webkit-scrollbar{
    display: none;
}
.detIAIL_HEADING_purchase{
    position: absolute;
    width: 100%;
    top: 50px;
    height: 41px;
    overflow: visible;
}
.purchase_heading{
    position: absolute;
    width: calc(100% - 20px);
    height: 25px;
    left: 10px;
    display: inline-flex;
}
#purchase_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 70.5%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Line_purchase_grid {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_purchase_grid {
    overflow: visible;
    position: absolute;
    width: 100%;
    height: 1px;
    top: 30px;
    transform: matrix(1,0,0,1,0,0);
}
#cost_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 89.5%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#expiry_purchase_grid_near_expiry {
    position: relative;
    overflow: visible;
    width: calc(100% - 87.5%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#status_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 91%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#contact_staff_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 85%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#view_invoice_purchase_grid {
    position: relative;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#category_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 87.5%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Scroll_purchase {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    top: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_purchase::-webkit-scrollbar{
    display: none;
}
#card1_example_purchase_grid {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 20px;
    left: 12px;
    top: -1px;
    overflow: visible;
}
.purchase_data{
    position: absolute;
    width: 100%;
    height: 31px;
    display: inline-flex;
}
#product_example_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 71%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#category_example_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 88.7%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Expiry_example_example_purchas {
    position: relative;
    overflow: visible;
    width: calc(100% - 87.5%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#cost_example_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 89%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.status_example_purchase_grid {
    position: relative;
    overflow: visible;
    width: calc(100% - 89%);
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}
#contact_staff_purchase_grid_dl {
    position: relative;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#purchases_btn_lbl {
    left: 89%;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_btn_purchase {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 85%;
    overflow: visible;
}
#add_bg_Add_purchases {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_purchases {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_Tax_purchaseyplus_ {
    fill: rgba(0,0,0,1);
}
.Add_Tax_purchaseyplus_ {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
