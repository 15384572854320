.overall_ea{
    position: absolute;
    width: calc(100% - 822px);
    height: calc(100% - 280px);
    top: 244px;
    min-width: 385px;
}
#expense_analytics_over_all_bdr {
    fill: rgba(255,255,255,1);
}
.expense_analytics_over_all_bdr {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
}
.expense_analytics_over_bdr {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 135%;
    height: 100%;
}
#Expense_Analytics_lbl_dash_pc {
    left: 10px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 143px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#all_grp_exxpense_alanytic {
    position: absolute;
    width: calc(97% - 1px);
    height: auto + 5px;
    left: 10px;
    top: 42px;
    overflow: visible;
    display: inline-flex;
}
#salaries_expense_analytuics_gr {
    cursor: pointer;
    position: relative;
    width: 20%;
    height: 100%;
    min-height: 28px;
    min-width: 70px;
    top: 0px;
    overflow: visible;
    padding-left: 5px;
    padding-bottom: 5px;
}
#salaries_expense_analytuics_gr:hover{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}   
#salaries_dash_pc {
    fill: rgba(106,169,255,1);
}
.salaries_dash_pc {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 6px;
    left: 0px;
    top: 0px;
}
.salary_ea_dash{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
    top: 8px;
    overflow: visible;
    margin-right: 6px;
}
#Salaries_lbl_dash_pc {
    left: 14px; 
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#salaries_dash_pc_e {
    fill: rgba(106,169,255,1);
}
.salaries_dash_pc_e {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
}
#n_100000_salary_dash_pc {
    top: 4px; 
    position: relative;
    overflow: visible;
    width: 68px;
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#bills_expense_analytuics_grp {
    cursor: pointer;
    position: relative;
    width: 20%;
    height: 100%;
    min-height: 28px;
    top: 0px;
    overflow: visible;
    min-width: 70px;
    padding-left: 5px;
    padding-bottom: 5px;
}
#bills_expense_analytuics_grp:hover{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}
#bills_bar_dash_pc {
    fill: #fc8787;
}
.bills_bar_dash_pc {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 6px;
    left: 0px;
    top: 0px;
}
.bill_ea_dash{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
    top: 8px;
    overflow: visible;
    margin-right: 6px;
}
#Bills_lbl_dash_pc {
    left: 14px;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#bills_circle_dash_pc {
    fill: #fc8787;
}
.bills_circle_dash_pc {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
}
#n_10000_bills_dash_pc {
    top: 4px;
    position: relative;
    overflow: visible;
    width: 68px;
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#taxes_expense_analytuics_grp {
    cursor: pointer;
    position: relative;
    width: 20%;
    height: 100%;
    min-height: 28px;
    top: 0px;
    overflow: visible;
    min-width: 70px;
    padding-left: 5px;
    padding-bottom: 5px;
}
#taxes_expense_analytuics_grp:hover{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}
#taxes_bar_dash_pc {
    fill: rgba(186,106,255,1);
}
.taxes_bar_dash_pc {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 6px;
    left: 0px;
    top: 0px;
}
.tax_ea_dash{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
    top: 8px;
    overflow: visible;
    margin-right: 6px;
}
#Taxes_lvbl_dash_pc {
    left: 14px;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#taxes_bdr_dash_pc {
    fill: rgba(186,106,255,1);
}
.taxes_bdr_dash_pc {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
}
#n_1000000_taxes_dash_pc {
    top: 4px;
    position: relative;
    overflow: visible;
    width: 68px;
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#purchase_expense_analytuics_gr {
    cursor: pointer;
    position: relative;
    width: 20%;
    height: 100%;
    min-height: 28px;
    top: 0px;
    overflow: visible;
    min-width: 70px;
    padding-left: 5px;
    padding-bottom: 5px;
}
#purchase_expense_analytuics_gr:hover{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}
#purchase_bdr_dash_pc {
    fill: rgba(252,135,135,1);
}
.purchase_bdr_dash_pc {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 6px;
    left: 0px;
    top: 0px;
}
.purchase_ea_dash{
    position: relative;
    display: inline-block;
    width: 55px;
    height: 18px;
    top: 8px;
    overflow: visible;
    margin-right: 6px;
}
#Purchases_lv_dash_pc {
    left: 14px;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#purchase_circe_dash_pc {
    fill: rgba(252,135,135,1);
}
.purchase_circe_dash_pc {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
}
#n_10000_purchase_dash_pc {
    top: 4px;
    position: relative;
    overflow: visible;
    width: 68px;
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#invoice_expense_analytuics_grp {
    cursor: pointer;
    position: relative;
    width: 20%;
    min-width: 70px;
    height: 100%;
    min-height: 28px;
    top: 0px;
    overflow: visible;
    padding-left: 5px;
    padding-bottom: 5px;
}
#invoice_expense_analytuics_grp:hover{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}
#invoice_bdr_progress_bar_dash_ {
    fill: rgba(197,197,197,1);
}
.invoice_bdr_progress_bar_dash_ {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 6px;
    left: 0px;
    top: 0px;
}
.invoice_ea_dash{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 18px;
    top: 8px;
    overflow: visible;
    margin-right: 6px;
}
#Invoices_lbl_dash_pc {
    left: 14px;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(0,0,0,1);
}
#invoice_color_circle_dash_pc {
    fill: rgba(197,197,197,1);
}
.invoice_color_circle_dash_pc {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
}
#n_10000_invoice_lbl_dash_pc {
    top: 4px;
    position: relative;
    overflow: visible;
    width: 68px;
    display: inline-block;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#add_btn_all {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    left: calc(100% - 7%);
    top: 7px;
    overflow: visible;
}
#add_bg_all {
    fill: rgba(252,135,135,1);
}
.add_bg_all {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_all {
    fill: rgba(0,0,0,1);
}
.Add_all {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}


.eaactive_tab{
    background-color: rgba(0, 0, 0, 0.103);
    border-radius: 5px;
}