.mediaViewInfo {
    --web-view-name: Footer app candi pc;
    --web-view-id: Footer_app_candi_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Footer_app_candi_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#Footer_app_candi_pc {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 40px;
    bottom: 0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Footer app candi pc;
    --web-view-id: Footer_app_candi_pc;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#footer_app_pc_candi {
    position: absolute;
    width: 100vw;
    height: 64px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#footer_vacan {
    fill: rgba(235, 56, 61, 1);
    opacity: 0.85;
}
.footer_vacan {
    position: absolute;
    overflow: visible;
    width: 100vw;
    height: 64px;
    left: 0px;
    top: 0px;
}
#All_rights_reserved__2022_Oper {
    left: 8%;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 491px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#linkedin_vacan {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 83%;
    top: 08px;
    overflow: visible;
}
#whatapp_btn_img_vacan {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 86.5%;
    top: 08px;
    overflow: visible;
}
#Terms_and_Conditions_lbl_vac {
    left: 53.5%;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 173px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Private_Policy_lbl_vac {
    left: 67%;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Line_2_vacan {
    fill: transparent;
    stroke: rgba(255,255,255,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_2_vacan {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 20px;
    left: 65%;
    top: 12px;
    transform: matrix(1,0,0,1,0,0);
}