.salary_overall_container{
    position: absolute;
    width: calc(100% - 547px);
    height: calc(100% - 90px);
    left: 182px;
    top: 50px;
}
.detIAIL_HEADING_invoice{
    position: absolute;
    width: 100%;
    top: 50px;
    height: 41px;
    overflow: visible;
}
.invoice_heading{
    position: absolute;
    width: 100%;
    height: 29px;
    top: 5px;
    display: inline-flex;
}
#Noinvoice {
    left: 18px;
    position: relative;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Client_invoice {
    /* left: 345px;
    top: 97px; */
    position: relative;
    overflow: visible;
    width: 200px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Date_invoice {
    /* left: 700px;
    top: 97px; */
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Date_paid {
    /* left: 700px;
    top: 97px; */
    position: relative;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#due_date_lbl_invoice_ {
    position: relative;
    overflow: hidden;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Amount_invoice {
    position: relative;
    overflow: visible;
    width: 200px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Amount_lbl {
    position: relative;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Status_invoice {
    position: relative;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#invoice_scroll_pc {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 110px);
    top: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#invoice_scroll_pc::-webkit-scrollbar{
    display: none;
}
#card_1_invoice {
    cursor: pointer;
    position: absolute;
    width: calc(100% - 10px);
    height: 48px;
    left: 6px;
    top: 4px;
    overflow: visible;
}
#card_bg {
    fill: rgba(255,255,255,1);
}
.card_bg {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 62px;
    left: 0px;
    top: 0px;
}
.invoice_data{
    position: absolute;
    width: 100%;
    height: 31px;
    display: inline-flex;
}
#BCS101_invoice {
    left: 10px;
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Mohammed_Affan_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 200px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Jun_21_2023_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#paid_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 135px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_23450_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 200px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_8_invoice {
    top: 11px;
    position: relative;
    overflow: hidden;
    width: 150px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
.status_inv{
    position: relative;
    width: 100px;
    height: 41px;
}
#Draft_invoice {
    left: 18px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}
.invoice_status {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 12px;
    /* left: 885px; */
    top: 17px;
}
#due_date_lbl {
    left: 704px;
    top: 97px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}

#add_btn_new_invoice {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 85%;
    overflow: visible;
}
#add_bg_Add_new_invoice {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_new_invoice {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_new_invoice {
    fill: rgba(0,0,0,1);
}
.Add_new_invoice {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#lbl_new_invoice {
    left: 89%;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}   