.mediaViewInfo {
    --web-view-name: new layout;
    --web-view-id: new_layout;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: new_layout;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#new_layout_expired {
    position: absolute;
    width: 100vw;
    height: calc(100% - 60px);
    overflow: hidden;
    min-width: 1300px;
    min-height: 613px;
    --web-view-name: new layout;
    --web-view-id: new_layout;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#top_lbls_purchase_grid_grp_expired {
    position: absolute;
    width: calc(100% - 510px);
    height: calc(100% - 60px);
    left: 162px;
    top: 41px;
    overflow: visible;
}
#new_layout_down_card_1_expired {
    fill: rgba(255,255,255,1);
}
.new_layout_down_card_1_expired {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: calc(100% - 50px);
    left: 0px;
    top: 46px;
}
#heading_grp_new_lay_expired {
    z-index: 12;
    position: absolute;
    width: calc(100% - 450px);
    height: 35px;
    left: 0px;
    top: 0px;
    overflow: visible;
    display: inline-flex;
    min-width: 482px;
}
#near_expiry_lbl_purchase_g_expired {
    cursor: pointer;
    left: 0px;
    top: 0px;
    position: relative;
    overflow: visible;
    width: calc(100% - 79%);
    min-width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#inuse__lbl_purchase_grid_expired {
    cursor: pointer;
    top: 0px;
    position: relative;
    overflow: visible;
    width: calc(100% - 82%);
    min-width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#instock_lbl_purchase_grid_expired {
    cursor: pointer;
    top: 0px;
    position: relative;
    overflow: visible;
    width: calc(100% - 81%);
    min-width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#expired_lbl_purchase_grid_expired {
    cursor: pointer;
    top: 0px;
    position: relative;
    overflow: visible;
    width: calc(100% - 81%);
    min-width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(252,135,135,1);
}
#undesrline_expired {
    fill: transparent;
    stroke: rgba(252,135,135,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.undesrline_expired {
    overflow: visible;
    position: absolute;
    width: 69px;
    height: 1px;
    left: calc(100% - 43.5%);
    top: 29px;
    transform: matrix(1,0,0,1,0,0);
}