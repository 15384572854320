#tabs_heading_dashboard_pv_grp_iu {
    position: absolute;
    width: 384px;
    height: 32px;
    left: 20px;
    overflow: visible;
}
#over_all_secter_bg_dash_pc_iu {
    fill: rgba(255,255,255,1);
}
.over_all_secter_bg_dash_pc_iu {
    filter: drop-shadow(5px 5px 2px rgba(203, 203, 203, 1));
    position: absolute;
    overflow: visible;
    width: 392px;
    height: 40px;
    left: 0px;
    top: 0px;
}
#mark_about_hanger_dash_pc_inuse {
    fill: rgba(252,135,135,1);
}
.mark_about_hanger_dash_pc_inuse {
    position: absolute;
    overflow: visible;
    width: 103px;
    height: 32px;
    left: 103px;
    top: 0px;
}
#near_expiry_lbl_purchase_grid_iu {
    cursor: pointer;
    left: 15px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#in_use__lbl_purchase_grid_dash_iu {
    cursor: pointer;
    left: 137px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#instock_lbl_purchase_grid_dash_iu {
    cursor: pointer;
    left: 222px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#expired_lbl_purchase_grid_dash_iu {
    cursor: pointer;
    left: 309px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

#stock_overview_bdr_lbl_dashboa_iu {
    fill: rgba(255,255,255,1);
}
.stock_overview_bdr_lbl_dashboa_iu {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: calc(100% - 46px);
    top: 40px;
}
#Stock_Overview_lbl_dashboard_p_iu {
    left: 10px;
    top: 45px;
    position: absolute;
    overflow: visible;
    width: 125px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}