.mediaViewInfo {
    --web-view-name: finance manager top right;
    --web-view-id: finance_manager_top_right;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: finance_manager_top_right;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#finance_manager_top_right {
    position: absolute;
    z-index: 10;
    width: 80px;
    height: 80px;
    right: 2vw;
    top: 2vw;
    border: 2px solid rgba(235, 56, 61, 1);
    border-radius: 80px;
    background-color: rgba(235, 56, 61, 1);
    opacity: 0.5;
    overflow: hidden;
    text-align: center;
    --web-view-name: finance manager top right;
    --web-view-id: finance_manager_top_right;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
/* #logo_Rectangle {
    fill: rgba(255,255,255,1);
} */
.logo_Rectangle {
    position: absolute;
    overflow: visible;
    width: 233px;
    height: 53px;
    left: 0px;
    top: 0px;
}
#name_Group_42 {
    position: absolute;
    width: 80px;
    height: 45px;
    left: 2px;
    top: 4px;
    overflow: visible;
}
#logo_Finance_Manager {
    top: 18px;
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: rgb(255, 251, 251);
}
#logo_common {
    position: relative;
    width: 55px;
    height: 50px;
    left: 0px;
    top: 0px;
    overflow: visible;
}