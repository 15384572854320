.mediaViewInfo {
    --web-view-name: dashboard;
    --web-view-id: dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: dashboard;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#dashboard_Mob {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    --web-view-name: dashboard;
    --web-view-id: dashboard;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_bg_design_mob_dashboard {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    overflow: visible;
}
.name_mob{
    position: absolute;
    width: 100%;
    height: 19px;
    display: inline-flex;
    top: 59px;
}
#Welcome_Mohammed_Affan__lbl_ex {
    position: relative;
    overflow: visible;
    height: 19px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
    margin-left: 20px;
}
#overall_grp_compo_mob {
    position: absolute;
    width: 100%;
    height: calc(100% - 10%);
    left: 9px;
    top: 84px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Scroll_top_cards_mob_grp {
    mix-blend-mode: normal;
    position: absolute;
    width: calc(100% - 5%);
    height: 170px;
    left: 2px;
    top: -6px;
    overflow-x: scroll;
    overflow-y: hidden;
}
#all_grp_card {
    position: absolute;
    width: 715px;
    height: 132px;
    left: 9px;
    top: 16px;
    overflow: visible;
}
#income_loss_card_grp_dashboard {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 490px;
    top: 0px;
    overflow: visible;
}
#income_loss_card {
    fill: rgba(255,255,255,1);
}
.income_loss_card {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#total_revenue_grp_dashboardmob {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 245px;
    top: 0px;
    overflow: visible;
}
#over_all_card_bdr_revenbue_das {
    fill: rgba(255,255,255,1);
}
.over_all_card_bdr_revenbue_das {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#top_img_total_dashboardmob {
    position: absolute;
    width: 199px;
    height: 30px;
    left: 13px;
    top: 7px;
    overflow: visible;
}
#Total_Revenue_lbl_dashboardmob {
    left: 0px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 113px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#bdr_img_revenue_dashboardmob {
    fill: rgba(209,255,229,1);
}
.bdr_img_revenue_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 169px;
    top: 0px;
}
#revenue_img_dashboardmob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 172px;
    top: 3px;
    overflow: visible;
}
#Column_Chart1top_dashboardmob {
    position: absolute;
    width: 104px;
    height: 48.001px;
    left: 108.47px;
    top: 64px;
    overflow: visible;
}
#graph_2_45_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_45_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 38.648px;
}
#graph_2_12_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_12_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.935px;
    left: 9.043px;
    top: 23.064px;
}
#graph_2_0_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_0_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 26.182px;
}
#graph_2_9_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_9_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 38.648px;
}
#graph_2_8_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_8_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 27.43px;
}
#graph_2_7_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_7_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 13.715px;
}
#graph_2_6_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_6_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 29.299px;
}
#graph_2_5_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_5_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 19.949px;
}
#graph_2_4_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_4_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.403px;
    left: 72.348px;
    top: 10.598px;
}
#graph_2_3_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_3_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 13.715px;
}
#graph_2_2_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_2_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 20.57px;
}
#graph_2_1_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_2_1_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 48px;
    left: 99.479px;
    top: 0px;
}
#Column_dashboardmob_1_shadow {
    opacity: 0.1;
    position: absolute;
    width: 104px;
    height: 37.403px;
    left: 108.47px;
    top: 94.054px;
    overflow: visible;
}
#graph_1_21_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_21_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 17.948px;
}
#graph_1_1_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_1_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.935px;
    left: 9.043px;
    top: 6.234px;
}
#graph_1_0_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_0_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 7.793px;
}
#graph_1_9_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_9_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 17.948px;
}
#graph_1_8_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_8_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 8.416px;
}
#graph_1_7_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_7_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 1.559px;
}
#graph_1_6_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_6_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 17.947px;
}
#graph_1_5_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_5_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 4.676px;
}
#graph_1_4_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_4_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.403px;
    left: 72.348px;
    top: 0px;
}
#graph_1_3_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_3_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 1.559px;
}
#graph_1_2_dashboardmob {
    fill: rgba(31,120,180,1);
}
.graph_1_2_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 4.987px;
}
#graph_1_1_dashboardmob_eh {
    fill: rgba(31,120,180,1);
}
.graph_1_1_dashboardmob_eh {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 17.897px;
    left: 99.479px;
    top: 17.947px;
}
#underline_revenuedashboardmob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.underline_revenuedashboardmob {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 13px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_lbl_revenu_dashboardmob {
    left: 13px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 9px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_2345_lbl_reven_dashboardmob {
    left: 23px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#dashboardmob_date_rdashboardmo {
    left: 13px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Icon_awesom_dashboardmob_minus {
    fill: rgba(238,119,119,1);
}
.Icon_awesom_dashboardmob_minus {
    overflow: visible;
    position: absolute;
    width: 13.411px;
    height: 13.762px;
    transform: translate(0.47px, 0px) matrix(1,0,0,1,12.5307,105.0008) rotate(180deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#Icon_awesom_dashboardmob_plus {
    fill: #2ECC71;
}
.Icon_awesom_dashboardmob_plus {
    overflow: visible;
    position: absolute;
    width: 13.411px;
    height: 13.762px;
    transform: translate(0.47px, 0px) matrix(1,0,0,1,12.5307,105.0008);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#n_2_ups_and_dashboardmob {
    left: 28px;
    top: 102px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(238,119,119,1);
}
#total_expense_carddashboardmob {
    position: absolute;
    width: 225px;
    height: 132px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#total_expense_dashboardmob {
    fill: rgba(255,255,255,1);
}
.total_expense_dashboardmob {
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 150px;
    left: 0px;
    top: 0px;
}
#Total_Expensedashboardmob {
    left: 13px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 110px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#card_grp_imgdashboardmob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 182px;
    top: 7px;
    overflow: visible;
}
#bdr_total_expense_dashboardmob {
    fill: rgba(255,204,204,1);
}
.bdr_total_expense_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
}
#budget_imgdashboardmob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#Colu_dashboardmob_upper_total_ {
    position: absolute;
    width: 104px;
    height: 48.001px;
    left: 108px;
    top: 64px;
    overflow: visible;
}
#top_graph_12_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_12_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 38.648px;
}
#top_graph_11_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_11_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.935px;
    left: 9.043px;
    top: 23.064px;
}
#top_graph_0_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_0_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 26.182px;
}
#top_graph_9_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_9_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 38.648px;
}
#top_graph_8_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_8_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 27.43px;
}
#top_graph_7_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_7_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 13.715px;
}
#top_graph_6_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_6_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 29.299px;
}
#top_graph_5_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_5_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 19.949px;
}
#top_graph_4_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_4_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.403px;
    left: 72.348px;
    top: 10.598px;
}
#top_graph_3_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_3_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 13.715px;
}
#top_graph_2_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_2_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 20.57px;
}
#top_graph_1_dashboardmob {
    fill: rgba(31,120,180,1);
}
.top_graph_1_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 48px;
    left: 99.479px;
    top: 0px;
}
#dashboardmobtotal_expense_grp {
    opacity: 0.1;
    position: absolute;
    width: 104px;
    height: 37.403px;
    left: 108px;
    top: 94.054px;
    overflow: visible;
}
#shadow_12_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_12_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 0px;
    top: 17.948px;
}
#shadow_11_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_11_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 24.935px;
    left: 9.043px;
    top: 6.234px;
}
#shadow_0dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_0dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 21.818px;
    left: 18.087px;
    top: 7.793px;
}
#shadow_9_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_9_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 9.351px;
    left: 27.13px;
    top: 17.948px;
}
#shadow_8_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_8_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 20.571px;
    left: 36.174px;
    top: 8.416px;
}
#shadow_7_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_7_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 45.217px;
    top: 1.559px;
}
#shadow_6_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_6_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 18.701px;
    left: 54.261px;
    top: 17.947px;
}
#shadow_5_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_5_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 28.052px;
    left: 63.305px;
    top: 4.676px;
}
#shadow_4_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_4_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 37.403px;
    left: 72.348px;
    top: 0px;
}
#shadow_3_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_3_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 34.286px;
    left: 81.392px;
    top: 1.559px;
}
#shadow_2_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_2_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 27.429px;
    left: 90.435px;
    top: 4.987px;
}
#shadow_1_dashboardmob {
    fill: rgba(31,120,180,1);
}
.shadow_1_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 4.522px;
    height: 17.897px;
    left: 99.479px;
    top: 17.947px;
}
#border_linevdashboardmob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.border_linevdashboardmob {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 13px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_lbl_dashboardmob {
    left: 13px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 9px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n_2345_moneydashboardmob {
    left: 23px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#n__Jan_2023_-_1_Jan_2024_dashb {
    left: 13px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Income__Loss_lbl_lbl_dashboard {
    left: 503px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#income_line_dashboardmob {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.income_line_dashboardmob {
    overflow: visible;
    position: absolute;
    width: 158px;
    height: 1px;
    left: 503px;
    top: 37px;
    transform: matrix(1,0,0,1,0,0);
}
#n_10000000_lbldashboardmob {
    left: 490px;
    top: 51px;
    position: absolute;
    overflow: visible;
    width: 226px;
    height: 47px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(0,0,0,1);
}
#money_income_bdr_dashboardmob {
    fill: rgba(209,255,229,1);
}
.money_income_bdr_dashboardmob {
    position: absolute;
    overflow: visible;
    width: 30px;
    height: 30px;
    left: 672px;
    top: 7px;
}
#money-bag_2_img_lbl_dashboardm {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 675px;
    top: 10px;
    overflow: visible;
}
#chart_overall_grp_mob {
    position: absolute;
    width: 100%;
    height: 263px;
    left: 1px;
    top: 192px;
    overflow: visible;
}
#expne_all_grp_expense_mob {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 173px;
    height: 263px;
    left: calc(100% - 99%);
    top: 0px;
    overflow: visible;
}
#pie_chart_expense_mob {
    position: absolute;
    width: 131px;
    height: 131px;
    top: 35px;
    overflow: visible;
}
#Expense_lbl_expense_mob {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 78px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#Unassigned_lbl_client_dash_mob {
    left: 15px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash_mob {
    fill: rgba(255,255,255,1);
}
.unassigned_grey_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 189px;
}
#Assigned_lbl_client_dash_mob {
    left: 108px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 66px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#yellow_assigned_client_dash_mo {
    fill: #fc8787;
}
.yellow_assigned_client_dash_mo {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 97px;
    top: 189px;
}
#Work_in_progress_client_dash_m {
    left: 108px;
    top: 243px;
    position: absolute;
    overflow: visible;
    width: 58px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#blue_work_in_progress_dash_mob {
    fill: rgba(255,36,36,1);
}
.blue_work_in_progress_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 97px;
    top: 249px;
}
#Completed_lbl_client_dash_mob {
    left: 111px;
    top: 213px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#completed_greeen_dash_mob {
    fill: rgba(106,169,255,1);
}
.completed_greeen_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 97px;
    top: 219px;
}
#Cancelled_lbl_client_dash_mob {
    left: 15px;
    top: 243px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash_mob {
    fill: rgba(153,153,153,1);
}
.red_cancelled_client_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 249px;
}
#On_Hold_lbl_client_dash_mob {
    left: 15px;
    top: 213px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob {
    fill: rgba(255,153,0,1);
}
.orange_on_hold_dash_mob {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 219px;
}
#stoc_k_grp_EMoinMobTask_Page_m {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 161px;
    height: 233px;
    left: calc(100% - 47%);
    top: 0px;
    overflow: visible;
}
#pie_chart_dash_pc {
    position: absolute;
    width: 131px;
    height: 131px;
    top: 35px;
    overflow: visible;
}
#Stock_Analytics_lbl_dash_pc {
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 143px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#Unassigned_lbl_client_dash_mob_gc {
    left: 15px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#unassigned_grey_dash_mob_gd {
    fill: rgba(255,0,0,1);
}
.unassigned_grey_dash_mob_gd {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 189px;
}
#Cancelled_lbl_client_dash_mob_ge {
    left: 109px;
    top: 183px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#red_cancelled_client_dash_mob_gf {
    fill: rgba(106,169,255,1);
}
.red_cancelled_client_dash_mob_gf {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 98px;
    top: 189px;
}
#On_Hold_lbl_client_dash_mob_gg {
    left: 15px;
    top: 213px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob_gh {
    fill: #fc8787;
}
.orange_on_hold_dash_mob_gh {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 0px;
    top: 219px;
}
#On_Hold_lbl_client_dash_mob_gi {
    left: 109px;
    top: 213px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#orange_on_hold_dash_mob_gj {
    fill: rgba(153,153,153,1);
}
.orange_on_hold_dash_mob_gj {
    position: absolute;
    overflow: visible;
    width: 10px;
    height: 10px;
    left: 98px;
    top: 219px;
}
#filter_mob {
    fill: rgba(255,255,255,1);
}
.filter_mob {
    position: relative;
    overflow: visible;
    width: 110px;
    height: 30px;
    top: -05px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
    margin-left: 08px;
}