
.stock_mpob {
    position: absolute;
    width: 100vw;
    height: 318px;
    top: 764px;
    left: 12px;
    overflow: hidden;
    --web-view-name: stock mpob;
    --web-view-id: stock_mpob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}