#right_side_component {
    position: absolute;
    z-index: 10;
    width: 280px;
    height: calc(100% - 85px);
    min-height: 593px;
    right: 1.5vw;
    overflow: hidden;
    --web-view-name: purchase side component;
    --web-view-id: purchase_side_component;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
