.bill_overall_container{
    position: absolute;
    width: calc(100% - 547px);
    height: calc(100% - 90px);
    left: 182px;
    top: 50px;
}
#bill_scroll_card {
    mix-blend-mode: normal;
    position: absolute;
    width: 100%;
    height: calc(100% - 45px);
    top: 35px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#bill_scroll_card::-webkit-scrollbar{
    display: none;
}
#card1_grp {
    cursor: pointer;
    position: absolute;
    width: 182px;
    height: 113px;
    left: 0px;
    top: -10px;
    overflow: visible;
}
#Path_3_card1 {
    fill: rgba(252,135,135,1);
}
#inside_bg_card1 {
    fill: rgba(113,233,165,1);
}
.inside_bg_card1 {
    position: absolute;
    overflow: visible;
    width: 182px;
    height: 113px;
    left: 13px;
    top: 31.266px;
}
#n_50_card1 {
    left: 18px;
    top: 81.266px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#img_card_ {
    position: absolute;
    width: 65px;
    height: 55px;
    left: 130.5px;
    top: 41.266px;
    overflow: visible;
}
#Groceries_card1 {
    left: 25.5px;
    top: 38.266px;
    position: absolute;
    overflow: visible;
    width: 97px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(4,4,4,1);
}
#view_btn_ {
    position: absolute;
    width: 56.752px;
    height: 28px;
    left: 40px;
    top: 112.266px;
    overflow: visible;
}
#view_btn_1 {
    fill: rgba(248,157,87,1);
    opacity: 0.85;
}
.view_btn_1 {
    position: absolute;
    overflow: visible;
    text-align: center;
    width: 60px;
    height: 28px;
    top: 0px;
}
#View_lbl {
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#pay_btn {
    position: absolute;
    width: 60px;
    height: 28px;
    left: 110.5px;
    top: 112px;
    overflow: visible;
}
#pay_btn_bg {
    fill: rgba(248,157,87,1);
    opacity: 0;
}
.pay_btn_bg {
    position: absolute;
    overflow: visible;
    width: 56.752px;
    height: 28px;
    left: 0px;
    top: 0px;
}
#Pay_lbl {
    left: -2px;
    top: 4px;
    position: absolute;
    text-align: center;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,255,255,1);
}
#Amount_card1 {
    left: 25.5px;
    top: 64.266px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(4,4,4,1);
}
#n__Days_card1 {
    left: 78px;
    top: 81.266px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
#Due_In_card1 {
    left: 92px;
    top: 64.266px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(4,4,4,1);
}
#add_btn_bill {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 85%;
    /* top: 55px; */
    overflow: visible;
}
#add_bg_Add_bill {
    fill: rgba(252,135,135,1);
}
.add_bg_Add_bill {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_bill {
    fill: rgba(0,0,0,1);
}
.Add_bill {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1,0,0,1,0,0);
}
#lbl_bill {
    left: 89%;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
