.mediaViewInfo {
    --web-view-name: new client popup- invoice;
    --web-view-id: new_client_popup-_invoice;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: new_client_popup-_invoice;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.pop_layout{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
#new_client_popup-_invoice {
    position: absolute;
    width: 389px;
    height: 768px;
    overflow: hidden;
    --web-view-name: new client popup- invoice;
    --web-view-id: new_client_popup-_invoice;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_up_bg_deisgn {
    position: absolute;
    width: 313px;
    height: 675px;
    left: 38px;
    top: 35px;
    overflow: visible;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Icon_ionic-md-pop_up_invoice {
    fill: rgba(28,26,26,1);
}
.Icon_ionic-md-pop_up_invoice {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: matrix(1,0,0,1,319,49) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#btn_grp_client_pop_up:hover {
    left: 115px;
    top: 628px;
}
#btn_grp_client_pop_up {
    position: absolute;
    width: 167px;
    height: 44px;
    left: 108px;
    top: 618px;
    overflow: visible;
    transition: all .3s ease-out;
}
#pop_up_invoice_btn {
    fill: rgba(252,135,135,1);
}
.pop_up_invoice_btn {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_Client_pop_up_invoice {
    left: 44px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_client_lbl_pfp_client_pop_ {
    left: 145px;
    top: 42px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#over_all_scroll_client {
    mix-blend-mode: normal;
    position: absolute;
    width: 313.447px;
    height: 494.663px;
    left: 38.136px;
    top: 83.504px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_grp_prof_client_pop_up {
    position: absolute;
    width: 280.583px;
    height: 54.48px;
    left: 16.864px;
    top: 0px;
    overflow: visible;
}
#name_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#name_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 280.583px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Address_grp_prof_client_pop_up {
    position: absolute;
    width: 280.727px;
    height: 101.638px;
    left: 16px;
    top: 336.175px;
    overflow: visible;
}
#address_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_input_pfp_client_pop_u {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_pfp_client_pop_u {
    position: absolute;
    overflow: visible;
    width: 280.727px;
    height: 81.422px;
    left: 0px;
    top: 20.216px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#city_grp_client_pop_up {
    position: absolute;
    width: 131.876px;
    height: 54.478px;
    left: 16px;
    top: 449.999px;
    overflow: visible;
}
#state_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 131.875px;
    height: 33.954px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#state_grp_client_pop_up {
    position: absolute;
    width: 131.876px;
    height: 54.478px;
    left: 162.5px;
    top: 450.589px;
    overflow: visible;
}
#state_lbl_pfp_client_pop_up_dh {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 34px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#state_input_pfp_client_pop_up_di {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp_client_pop_up_di {
    position: absolute;
    overflow: visible;
    width: 131.875px;
    height: 33.954px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Pin_grp_prof_client_pop_up {
    position: absolute;
    width: 280.583px;
    height: 54.48px;
    left: 16px;
    top: 514.997px;
    overflow: visible;
}
#Pin_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 22px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Pin_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 280.583px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#PAN_prof_client_pop_up {
    position: absolute;
    width: 281.447px;
    height: 54.48px;
    left: 16px;
    top: 199.511px;
    overflow: visible;
}
#PAN_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#PAN_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.PAN_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 281.447px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#GSTIN_grp_client_pop_up {
    position: absolute;
    width: 280.583px;
    height: 54.478px;
    left: 16px;
    top: 134.513px;
    overflow: visible;
}
#GSTIN_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#gstin_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gstin_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 280.583px;
    height: 33.954px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#information_grp_client_pop_up {
    position: absolute;
    width: 280.727px;
    height: 101.633px;
    left: 16px;
    top: 579.997px;
    overflow: visible;
}
#company_lbl_client_pop_uppop_u {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#company_inputclient_pop_up_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.company_inputclient_pop_up_pop {
    position: absolute;
    overflow: visible;
    width: 280.727px;
    height: 81.419px;
    left: 0px;
    top: 20.214px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#active_btn_client_pop_up {
    position: absolute;
    width: 85.903px;
    height: 20px;
    left: 113.001px;
    top: 696.663px;
    overflow: visible;
}
#switch_area_pop_up_invoice_cli {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.switch_area_pop_up_invoice_cli {
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 16px;
    left: 0px;
    top: 2px;
}
#company_lbl_pfp_pop_up_invoice {
    left: 45.903px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_grp_prof_client_pop_up {
    position: absolute;
    width: 132.667px;
    height: 54.48px;
    left: 16.072px;
    top: 67.257px;
    overflow: visible;
}
#email_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 132.667px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#mob_nuimber_grp_prof_client_po {
    position: absolute;
    width: 132.667px;
    height: 54.48px;
    left: 164.78px;
    top: 67.257px;
    overflow: visible;
}
#mob_nu_lbl_pfp_client_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#MOb_nu_input_pfp_client_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.MOb_nu_input_pfp_client_pop_up {
    position: absolute;
    overflow: visible;
    width: 132.667px;
    height: 33.956px;
    left: 0px;
    top: 20.524px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#freq_lbl_pfp_client_pop_up_ {
    left: 16px;
    top: 269.511px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#freq_input_pfp_client_pop_up_f {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.freq_input_pfp_client_pop_up_f {
    position: absolute;
    overflow: visible;
    width: 132.739px;
    height: 33.956px;
    left: 16px;
    top: 289.546px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#due_on_lbl_pop_up_client {
    left: 160.864px;
    top: 270.496px;
    position: absolute;
    overflow: visible;
    width: 26px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_on_input_pop_up_day_client {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_up_day_client {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 33px;
    left: 161.112px;
    top: 290.164px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_input_pop_up_client_fre {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_on_input_pop_up_client_fre {
    position: absolute;
    overflow: visible;
    width: 66.846px;
    height: 33px;
    left: 229.737px;
    top: 290.141px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#due_on_lbl_pop_up_client_frw {
    left: 229.7px;
    top: 270.496px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}