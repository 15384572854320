.mediaViewInfo {
    --web-view-name: small nav finance;
    --web-view-id: small_nav_finance;
    --web-scale-on-resize: false;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: small_nav_finance;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#small_nav_finance {
    position: fixed;
    width: 160px;
    z-index: 10;
    height: calc(100vh - 60px);
    overflow: hidden;
    --web-view-name: small nav finance;
    --web-view-id: small_nav_finance;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Group_2 {
    position: absolute;
    width: 112px;
    height: calc(100vh - 100px);
    left: 30px;
    top: 36px;
    overflow: visible;
}
#side_nav_icon_border {
    fill: rgba(235, 56, 61, 1);
    opacity: 0.85;
}
.side_nav_icon_border {
    position: absolute;
    overflow: visible;
    width: 112px;
    height: 100vh;
    left: 0px;
    top: 0px;
}
#over_all_grp {
    position: absolute;
    width: 70px;
    height: calc(100vh - 150px);
    left: 22px;
    top: 28px;
    overflow: visible;
    display: grid;
}
#side_logo_icon {
    cursor: pointer;
    position: relative;
    width: 70px;
    height: 70px;
    left: 0px;
    /* top: 0px; */
    border-radius: 50%;
    overflow: hidden;
}
#dashboard_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 100px; */
    overflow: visible;
}
#dashboard_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#dashboard_icon {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#salary_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 172px; */
    overflow: visible;
}
#salary_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#salary_Icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 5px;
    overflow: visible;
}
#offering_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 370px; */
    overflow: visible;
}
#offering_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#offerings_icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 6px;
    overflow: visible;
}
#connection_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 305px; */
    overflow: visible;
}
#connection_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#my_connections_icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}
#logout_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 435px; */
    overflow: visible;
}
#logout_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#logout_icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 0px;
    overflow: visible;
}
#logout_icon_I_ {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 35px;
    overflow: visible;
}
#com_grp {
    position: relative;
    width: 45px;
    height: 45px;
    left: 13px;
    /* top: 238px; */
    overflow: visible;
}
#com_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
#community_icon_I {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 5px;
    top: 6px;
    overflow: visible;
}
#setup_grp{
        position: relative;
		width: 45px;
		height: 45px;
		left: 15px;
		/* top: 500px; */
		overflow: visible;
}
#setup_grp:hover{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}
.setup_icon{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}


#logout1_grp{
    cursor: pointer;
    position: relative;
    width: 45px;
    height: 45px;
    left: 15px;
    /* top: 537px; */
    overflow: visible;
}
.logout_icon_I_{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 6px;
    overflow: visible;
}
.active_page{
    border: 2px solid rgba(255,255,255,1);
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
}






/* Animation */
@keyframes slideInRight {
    from {
      transform: translate3d(2.5%, 0, 0);
      visibility: visible;
      position: relative;
        z-index: 10;
    }
  
    to {
      transform: translate3d(0, 0, 0);
      position: relative;
        z-index: 10;
    }
  }
  
  .slideInRight {
    animation: slideInRight 1s;
  }







