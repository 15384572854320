.mediaViewInfo {
    --web-view-name: setup pop up;
    --web-view-id: setup_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: setup_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.user_popup_layout{
    display: flex;
    justify-content: center;
}
#setup_pop_up {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    --web-view-name: setup pop up;
    --web-view-id: setup_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
.changePassUser{
    cursor: pointer;
    position: absolute;
    z-index: 10;
    width: 28px;
    height: 28px;
    top: 185px;
    left: 830px;
}
#pop_up_user_bg {
    filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 437px;
    height: 649px;
    left: 540px;
    top: 44px;
    overflow: visible;
}
#pfp_behind_circle_popup {
    fill: rgba(255,255,255,1);
}
.pfp_behind_circle_popup {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 118px;
    left: 711px;
    top: 64px;
}
#profile_pfp_img {
    cursor: pointer;
    position: absolute;
    width: 80px;
    height: 80px;
    left: 721px;
    top: 74px;
    border-radius: 50%;
    overflow: hidden;
}
#state_grp_prof {
    position: absolute;
    width: 397.576px;
    height: 63px;
    left: 561px;
    top: 232px;
    overflow: visible;
}
#state_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#state_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp {
    position: absolute;
    overflow: visible;
    width: 397.576px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#city_grp_prof {
    position: absolute;
    width: 186.797px;
    height: 63px;
    left: 767px;
    top: 304.991px;
    overflow: visible;
}
#city_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#city_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_pfp {
    position: absolute;
    overflow: visible;
    width: 186.797px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#company_grp {
    position: absolute;
    width: 186.797px;
    height: 63px;
    left: 561px;
    top: 305px;
    overflow: visible;
}
#company_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#company_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.company_input_pfp {
    position: absolute;
    overflow: visible;
    width: 186.797px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#office_grp {
    position: absolute;
    width: 186.798px;
    height: 63px;
    left: 561px;
    top: 377.982px;
    overflow: visible;
}
#office_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 91px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#office_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.office_input_pfp {
    position: absolute;
    overflow: visible;
    width: 186.797px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#latitiude_grp {
    position: absolute;
    width: 397.576px;
    height: 107px;
    left: 561px;
    top: 451px;
    overflow: visible;
}
#latitude_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#latitude_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.latitude_input {
    position: absolute;
    overflow: hidden;
    overflow-y: scroll;
    width: 397.576px;
    height: 84px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#active_lbl_popup{
    left: 722px;
    top: 183.004px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 211.714px;
    top: 187.004px;
}
#change_pass_btn_grp:hover {
    top: 608px;
}
#change_pass_btn_grp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 697px;
    top: 603px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#passs_bdr_btn {
    fill: #fc8787;
}
.passs_bdr_btn {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#add_lbl {
    left: 43.914px;
    top: 7.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#close_userpopup {
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 930px;
    top: 61px;
    overflow: visible;
}
#office_grp_bz {
    position: absolute;
    width: 186.798px;
    height: 63px;
    left: 275px;
    top: 377.982px;
    overflow: visible;
}
#office_lbl_pfp_b {
    left: 497px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#office_input_pfp_b {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.office_input_pfp_b {
    position: absolute;
    overflow: visible;
    width: 186.797px;
    height: 40px;
    left: 497px;
    top: 23px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
.loader_layout {
  position: absolute;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
}