.mediaViewInfo {
    --web-view-name: small nav bills;
    --web-view-id: small_nav_bills;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: small_nav_bills;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#small_nav_bills {
	width: 100vw;
	height: 100vh;
    overflow: hidden;
    --web-view-name: small nav bills;
    --web-view-id: small_nav_bills;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_page {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    left: 0px;
    top: 0px;
    overflow: visible;
}
