.mediaViewInfo {
    --web-view-name: pc login – 2;
    --web-view-id: pc_login__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: pc_login__2;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#pc_login__2 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    min-width: 1292px;
    min-height: 650px;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    --web-view-name: pc login – 2;
    --web-view-id: pc_login__2;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#n_9962869_6204361 {
    position: absolute;
    width: 773px;
    height: 540px;
    left: 30%;
    top: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
}

#Group_1 {
    position: absolute;
    width: 155px;
    height: 200px;
    left: 1211px;
    top: 568px;
    overflow: visible;
}

#mid_overall {
    position: absolute;
    width: 392px;
    height: 458px;
    right: 20%;
    top: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
}

#bg_dummy {
    fill: transparent;
}

.bg_dummy {
    position: absolute;
    overflow: visible;
    width: 392px;
    height: 413px;
    left: 0px;
    top: 45px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

#logo {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 151px;
    top: 0px;
    overflow: visible;
}

#Expense_Manager {
    left: 45px;
    top: 113px;
    position: absolute;
    overflow: visible;
    width: 313px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: #768691;
}

#btn {
    position: absolute;
    width: 305px;
    height: 45px;
    left: 44px;
    top: 348px;
    overflow: visible;
    border-radius: 5px;
}

#Rectangle_3_p {
    fill: url(#Rectangle_3_p);
}

.Rectangle_3_p {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 45px;
    left: 0px;
    top: 0px;
}

#Sign_in {
    left: 126px;
    top: 12px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255, 255, 255, 1);
}

#username {
    fill: rgba(254, 254, 254, 1);
}

.username {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 46px;
    left: 44px;
    top: 188px;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
    margin-bottom: 30px;
    border-bottom: solid rgba(252,135,135,1);
}

#password {
    fill: rgba(255, 255, 255, 1);
}

.password {
    position: absolute;
    overflow: visible;
    width: 305px;
    height: 46px;
    left: 44px;
    top: 248px;
    border-radius: 5px;
    padding: 5px;
    background: transparent;
    margin-bottom: 30px;
    border-bottom: solid rgba(252,135,135,1);
}

#Forgot_Password1 {
    left: 231px;
    top: 306px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255, 71, 71, 1);
}