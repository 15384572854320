.mediaViewInfo {
    --web-view-name: add vendor pop up;
    --web-view-id: add_vendor_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: add_vendor_pop_up;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.vendors_layout{
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
#add_vendor_pop_up {
    position: absolute;
    width: 370px;
    height: 625px;
    overflow: hidden;
    --web-view-name: add vendor pop up;
    --web-view-id: add_vendor_pop_up;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_grp_add_vendor {
    position: absolute;
    width: 230px;
    height: 580px;
    left: 79px;
    top: 29px;
    overflow: visible;
}
#Rectangle_36 {
    fill: rgba(255,255,255,1);
}
.Rectangle_36 {
    filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 238px;
    height: 580px;
    left: 0px;
    top: 0px;
}
#vendor_name__grp_prof {
    position: absolute;
    width: 192.583px;
    height: 55.387px;
    left: 19px;
    top: 78.795px;
    overflow: visible;
}
#vendor_name__lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#vendor_name__input_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vendor_name__input_ {
    position: absolute;
    overflow: visible;
    width: 192.583px;
    height: 34.521px;
    left: 0px;
    top: 20.866px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#BA_grp_prof {
    position: absolute;
    width: 192.583px;
    height: 55.387px;
    left: 19px;
    top: 139.795px;
    overflow: visible;
}
#BA_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BA_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.BA_input_pfp {
    position: absolute;
    overflow: visible;
    width: 192.583px;
    height: 34.521px;
    left: 0px;
    top: 20.866px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#due_date_grp {
    position: absolute;
    width: 192.583px;
    height: 55.387px;
    left: 19px;
    top: 200.794px;
    overflow: visible;
}
#due_date_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 42px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_date_input_pfp {
    position: absolute;
    overflow: visible;
    width: 192.583px;
    height: 34.521px;
    left: 0px;
    top: 20.866px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#btn_grp {
    position: absolute;
    width: 123px;
    height: 39px;
    left: 54px;
    top: 517px;
    overflow: visible;
    border-radius: 10px;
}
#bg_btn {
    fill: #fc8787;
}
.bg_btn {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 39px;
    left: 0px;
    top: 0px;
}
#Add_Vendor_btn {
    left: 22px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Path_3 {
    fill: #fc8787;
}
.Path_3 {
    overflow: visible;
    position: absolute;
    width: 230px;
    height: 40.793px;
    left: 0px;
    top: 0.349px;
    transform: matrix(1,0,0,1,0,0);
}
#Add_Vendor_lbl {
    left: 68px;
    top: 5px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#close_pup_op_staff {
    cursor: pointer;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 205px;
    top: 6px;
    overflow: visible;
}
#due_date_grp_br {
    position: absolute;
    width: 192.583px;
    height: 55.387px;
    left: 19px;
    top: 261.793px;
    overflow: visible;
}
#due_date_lbl_pfp_bs {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_input_pfp_bt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_date_input_pfp_bt {
    position: absolute;
    overflow: visible;
    width: 192.583px;
    height: 34.521px;
    left: 0px;
    top: 20.866px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#due_date_grp_bx {
    position: absolute;
    width: 192.583px;
    height: 75.72px;
    left: 19px;
    top: 388px;
    overflow: visible;
}
#due_date_lbl_pfp_by {
    left: 1px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#due_date_input_pfp_bz {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.due_date_input_pfp_bz {
    position: absolute;
    overflow: visible;
    width: 192.583px;
    height: 54.854px;
    left: 0px;
    top: 20.866px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#electricty_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 100px;
    top: 80px;
    overflow: visible;
}
#water_supply_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 130px;
    top: 80px;
    overflow: visible;
}
#wifi_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 160px;
    top: 80px;
    overflow: visible;
}
#iphone-5_imgg {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 190px;
    top: 80px;
    overflow: visible;
}
#Grocery_img {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 220px;
    top: 80px;
    overflow: visible;
}
#plus_add {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 250px;
    top: 80px;
    overflow: visible;
}
#active_grp {
    position: absolute;
    width: 83.572px;
    height: 20px;
    left: 207px;
    top: 507px;
    overflow: visible;
}
#active_lbl {
    left: 43.572px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 0px;
    top: 0px;
}
#day_lbl_vendoirs {
    left: 99px;
    top: 352px;
    position: absolute;
    overflow: visible;
    width: 28px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#day_input_vendors {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.day_input_vendors {
    position: absolute;
    overflow: visible;
    width: 94.035px;
    height: 34.52px;
    left: 99px;
    top: 374.991px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#month_input_vendors {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.month_input_vendors {
    position: absolute;
    overflow: visible;
    width: 94.035px;
    height: 34.52px;
    left: 195.537px;
    top: 374.965px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#month_lbl_vendors {
    left: 196px;
    top: 352px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
