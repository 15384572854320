.mediaViewInfo {
    --web-view-name: purchase side component;
    --web-view-id: purchase_side_component;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

:root {
    --web-view-ids: purchase_side_component;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}

#add_btn_invoice_right {
    cursor: pointer;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 146px;
    top: 289px;
    overflow: visible;
}

#add_bg_Add_invoice_right {
    fill: rgba(232, 239, 246, 1);
}

.add_bg_Add_invoice_right {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 44px;
    height: 44px;
    left: 0px;
    top: 0px;
}

#Add_invoice_right {
    fill: rgba(0, 0, 0, 1);
}

.Add_invoice_right {
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    left: 4.09px;
    top: 4px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#scroll_left_component_invoice_right {
    mix-blend-mode: normal;
    position: absolute;
    width: 250px;
    height: calc(100vh - 400px);
    left: 0px;
    top: 370px;
    overflow-x: hidden;
    overflow-y: scroll;
}

#tax_authority_card_1_invoice_right {
    cursor: pointer;
    position: absolute;
    width: 197px;
    height: 117px;
    left: 27px;
    top: 7px;
    overflow: visible;
}

#card_1_bdr_invoice_right {
    fill: transparent;
}

.card_1_bdr_invoice_right {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: 117px;
    left: 0px;
    top: 0px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 8px 8px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

#Tax_Authority_lbl_invoice_right {
    left: 40px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

#Registration_Number_invoice_right {
    left: 40px;
    top: 53px;
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

#State_tax_authority_invoice_right {
    left: 40px;
    top: 94px;
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 17px;
    text-align: left;
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

#btw_tax_and_reg_invoice_right {
    fill: transparent;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.btw_tax_and_reg_invoice_right {
    overflow: visible;
    position: absolute;
    width: 184px;
    height: 1px;
    left: 4.5px;
    top: 40.5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#between_reg_and_state_line_invoice_right {
    fill: transparent;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.between_reg_and_state_line_invoice_right {
    overflow: visible;
    position: absolute;
    width: 184px;
    height: 1px;
    left: 4.5px;
    top: 82.5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#taxes_1__img_all_invoice_right {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 7px;
    overflow: visible;
}

#reg__img_all_invoice_right {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 49px;
    overflow: visible;
}

#location_img_all_invoice_right {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 11px;
    top: 91px;
    overflow: visible;
}

#filter_invoice_right {
    fill: rgba(255, 255, 255, 1);
}

.filter_invoice_right {
    position: absolute;
    overflow: visible;
    width: 197px;
    height: 38px;
    left: 27px;
    top: 327px;
    border-radius: 10px;
    padding-left: 10px;
}

#search_invoice_right {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 194px;
    top: 336px;
    overflow: visible;
}

#lbl_tax_page_pc_invoice_right {
    left: 85px;
    top: 295px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: rgba(0, 0, 0, 1);
}

.colour_invoice_right {
    color: rgba(31, 120, 180, 1);
}

#invoice_chart {
    position: absolute;
    left: -18px;
    top: 95px;
    width: 180px;
    height: 180px;
}