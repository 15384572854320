.mediaViewInfo {
		--web-view-name: tax pop up 1;
		--web-view-id: tax_pop_up_1;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: tax_pop_up_1;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
    .addtaxauthority_layout{
        display: flex;
        justify-content: center;
		margin-top: 50px;
    }
	#tax_pop_up_1 {
		position: absolute;
		width: 417px;
		height: 624px;
		/* background-color: rgba(255,255,255,1); */
		overflow: hidden;
		--web-view-name: tax pop up 1;
		--web-view-id: tax_pop_up_1;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#over_all_tax_auth_pop_up_grp {
		position: absolute;
		width: 378px;
		height: 580px;
		left: 20px;
		top: 22px;
		overflow: visible;
	}
	#bg_of_add_tax_auth_pop_up_tax_ {
		filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		width: 378px;
		height: 580px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#tax_auth_lbl_pop_up_tax_auth {
		left: 22.163px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 93px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#tax_auth_input_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.tax_auth_input_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 157.258px;
		height: 40px;
		left: 22.163px;
		top: 97px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#due_on_lbl_pop_up_tax_auth {
		left: 195.982px;
		top: 146.991px;
		position: absolute;
		overflow: visible;
		width: 28px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#due_on_input_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.due_on_input_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 77.687px;
		height: 40px;
		left: 195.982px;
		top: 169.991px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#fre_lbl_pop_up_tax_auth {
		left: 22.162px;
		top: 147px;
		position: absolute;
		overflow: visible;
		width: 74px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#freq_input_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.freq_input_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 157.258px;
		height: 40px;
		left: 22.162px;
		top: 170px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#state_lbl_pop_up_tax_auth {
		left: 22.163px;
		top: 220px;
		position: absolute;
		overflow: visible;
		width: 36px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#state_drop_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.state_drop_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 331.261px;
		height: 40px;
		left: 22.163px;
		top: 242px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#active_grp_pop_up_tax_auth {
		position: absolute;
		width: 97.572px;
		height: 27px;
		left: 253.597px;
		top: 444.5px;
		overflow: visible;
	}
	#active_lbl_pop_up_tax_auth {
		left: 43.572px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#active_chkbx_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.active_chkbx_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 38px;
		height: 20px;
		left: 0px;
		top: 4px;
	}
	#pop_up_tax_auth_btn_grp:hover {
		top: 507px;
	}
	#pop_up_tax_auth_btn_grp {
		position: absolute;
		width: 123px;
		height: 41px;
		left: 128px;
		top: 502px;
		overflow: visible;
		border-radius: 20px;
		transition: all .3s ease-out;
	}
	#passs_bdr_btn_pop_up_tax_auth {
		fill: rgba(106,169,255,1);
	}
	.passs_bdr_btn_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 123px;
		height: 41px;
		left: 0px;
		top: 0px;
	}
	#add_lbl_pop_up_tax_auth {
		left: 43.914px;
		top: 7.28px;
		position: absolute;
		overflow: visible;
		width: 37px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(255,255,255,1);
	}
	#close_pop_up_tax_auth {
		cursor: pointer;
		position: absolute;
		width: 25px;
		height: 25px;
		left: 335px;
		top: 13px;
		overflow: visible;
	}
	#reg_no_lbl_pop_up_tax_auth {
		left: 196.169px;
		top: 73px;
		position: absolute;
		overflow: visible;
		width: 156px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#redistration_n0_input_pop_up_t {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.redistration_n0_input_pop_up_t {
		position: absolute;
		overflow: visible;
		width: 157.254px;
		height: 40px;
		left: 196.169px;
		top: 97px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#payent_lbl_pop_up_tax_auth {
		left: 22.162px;
		top: 292px;
		position: absolute;
		overflow: visible;
		width: 94px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#ayment_url_input_pop_up_tax_au {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.ayment_url_input_pop_up_tax_au {
		position: absolute;
		overflow: visible;
		width: 331.261px;
		height: 40px;
		left: 22.162px;
		top: 315px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#add_tax_authority_heading_lbl_ {
		left: 103px;
		top: 14px;
		position: absolute;
		overflow: visible;
		width: 173px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#due_on_input_pop_up_tax_auth_b {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.due_on_input_pop_up_tax_auth_b {
		position: absolute;
		overflow: visible;
		width: 77.687px;
		height: 40px;
		left: 275.737px;
		top: 169.965px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}
	#due_on_lbl_pop_up_tax_auth_b {
		left: 275.982px;
		top: 146.991px;
		position: absolute;
		overflow: visible;
		width: 48px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#UPI_lbl_pop_up_tax_auth {
		left: 22.162px;
		top: 365px;
		position: absolute;
		overflow: visible;
		width: 89px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(0,0,0,1);
	}
	#UPIinput_pop_up_tax_auth {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UPIinput_pop_up_tax_auth {
		position: absolute;
		overflow: visible;
		width: 331.261px;
		height: 40px;
		left: 22.162px;
		top: 388px;
		font-size: 15px;
		border: 2px solid rgba(252,135,135,1);
		border-radius: 10px;
		padding: 5px;
	}