.mediaViewInfo {
    --web-view-name: pop up salay;
    --web-view-id: pop_up_salay;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_salay;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.addsalary_layout{
    display: flex;
    justify-content: center;
}
#pop_up_salay {
    position: absolute;
    width: 470px;
    height: 893px;
    /* background-color: rgba(255,255,255,1); */
    overflow: hidden;
    --web-view-name: pop up salay;
    --web-view-id: pop_up_salay;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_up_bg_pop__salary_bg {
    /* opacity: 0.78; */
    position: absolute;
    width: 313px;
    /* height: 505px; */
    left: 35px;
    top: 26px;
    overflow: visible;
    background:  rgba(255, 255, 255, 0.966);
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#namepop__salary {
    left: 52px;
    top: 74.504px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#name_input_ppop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_ppop__salary {
    position: absolute;
    overflow: visible;
    width: 102px;
    height: 33.956px;
    left: 52px;
    top: 95.029px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
.accname_input_ppop__salary {
    position: absolute;
    overflow: visible;
    width: 131.5px;
    height: 33.956px;
    left: 52px;
    top: 95.029px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Pin_lblpop__salary {
    left: 52px;
    /* top: 382.88px; */
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Pin_ipop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_ipop__salary {
    position: absolute;
    overflow: visible;
    width: 131.464px;
    height: 33.956px;
    left: 52px;
    top: 403.404px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#GSTIN_pop__salary {
    left: 163.708px;
    top: 74.507px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#gstinpop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gstinpop__salary {
    position: absolute;
    overflow: visible;
    width: 169.019px;
    height: 33.954px;
    left: 163.708px;
    top: 95.03px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#tax_auth_innput_pop_up_salary{
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
#Icon_ionic-mpop__salary {
    fill: rgba(28,26,26,1);
}
.Icon_ionic-mpop__salary {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: matrix(1,0,0,1,316,40) rotate(45deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#btn_grp_pop_pop__salary:hover {
    left: 113px;
    /* top: 473.379px; */
}
#btn_grp_pop_pop__salary {
    position: absolute;
    width: 167px;
    height: 44px;
    left: 108px;
    top: 570px;
    overflow: visible;
    transition: all .3s ease-out;
}
#pop_up_invoice_btn {
    fill: rgba(252,135,135,1);
}
.pop_up_invoice_btn {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_Client_pop_up_invoice_salary {
    left: 44px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_clienpop__salary {
    left: 142px;
    top: 33px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#project_pop__salary {
    left: 52px;
    top: 139px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#project__mob_puppop__salaryf {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project__mob_puppop__salaryf {
    position: absolute;
    overflow: visible;
    width: 131.464px;
    height: 40px;
    left: 52px;
    top: 160px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#project__mob_lbpop__salary {
    left: 202.263px;
    top: 139px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#project_mob_pop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.project_mob_pop__salary {
    position: absolute;
    overflow: visible;
    width: 131.464px;
    height: 40px;
    left: 201.263px;
    top: 160px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Pin_lbl_pop__salary {
    left: 201.123px;
    /* top: 382px; */
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#Pin_input_pop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_input_pop__salary {
    position: absolute;
    overflow: visible;
    width: 131.46px;
    height: 33.956px;
    left: 201.123px;
    /* top: 403.524px; */
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#Rectangle_491_pop__salary {
    fill: rgba(255,255,255,1);
}
.Rectangle_491_pop__salary {
    position: absolute;
    overflow: visible;
    width: 295px;
    height: 72px;
    left: 43px;
    top: 220px;
}
#Rectangle_50 {
    fill: rgba(255,255,255,1);
}
.Rectangle_50 {
    position: absolute;
    overflow: visible;
    width: 295px;
    height: 72px;
    left: 43px;
}
#Group_40 {
    position: absolute;
    width: 278px;
    height: 54.95px;
    left: 52px;
    overflow: visible;
}
#address_lbl_pfp {
    left: 0px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 89px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#address_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_pfp {
    position: relative;
    overflow: visible;
    width: 160.39px;
    height: 33.95px;
    left: 0px;
    margin: 2px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#add-button {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 258px;
    top: 0px;
    overflow: visible;
}
#address_input_pfp_bx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_pfp_bx {
    position: relative;
    overflow: visible;
    width: 70.123px;
    height: 33.95px;
    margin: 2px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
.minus_1 {
    position: relative;
    width: 15px;
    height: 15px;
    margin: 2px;
    overflow: visible;
}
#Group_39 {
    position: absolute;
    width: 278px;
    height: 54.954px;
    left: 52px;
    top: 229px;
    overflow: visible;
}
#office_lbl_pfp_pop_up_invoice {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 68px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#office_input_pfp_pop_up_invoic {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.office_input_pfp_pop_up_invoic {
    position: relative;
    overflow: visible;
    width: 171.39px;
    height: 33.954px;
    top: 0px;
    left: 0px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#add-button_b {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 258px;
    top: 0px;
    overflow: visible;
}
#address_input_pfp_b {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.address_input_pfp_b {
    position: relative;
    overflow: visible;
    width: 70.123px;
    height: 33.95px;
    /* left: 181.877px; */
    margin: 2px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
.minus_1_b {
    position: relative;
    width: 15px;
    height: 15px;
    /* left: 261px; */
    overflow: visible;
}
#approved_by_pop__salary {
    left: 52px;
    top: 436.48px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#paid_through_pop__salary {
    left: 52px;
    top: 458.48px;
    position: absolute;
    overflow: visible;
    width: 86px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#approved_by__mob_puppop__salar {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.approved_by__mob_puppop__salar {
    position: absolute;
    overflow: visible;
    width: 131.708px;
    height: 40px;
    left: 52px;
    top: 457.48px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
.paid_through__mob_puppop__salar {
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 40px;
    left: 52px;
    top: 537.48px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#overdue_by_pop__salary {
    left: 201.019px;
    top: 436px;
    position: absolute;
    overflow: visible;
    width: 64px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#overdue_by__mob_puppop__salary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.overdue_by__mob_puppop__salary {
    position: absolute;
    overflow: visible;
    width: 131.708px;
    height: 40px;
    left: 201.019px;
    top: 457px;
    font-size: 13px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}