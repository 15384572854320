.mediaViewInfo {
    --web-view-name: setup page;
    --web-view-id: setup_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: setup_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
body::-webkit-scrollbar{
    display: none;
}
#setup_page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    --web-view-name: setup page;
    --web-view-id: setup_page;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#set_up__bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -10;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#page_title_users{
    left: 183px;
    top: 35px;
    position: absolute;
    overflow: visible;
    width: 302px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#setup_scroll_pc {
    mix-blend-mode: normal;
    position: relative;
    display: block;
    width: calc(100vw - 200px);
    height: calc(100% - 136px);
    min-height: 613px;
    min-width: 120px;
    left: 162px;
    top: 36px;
    overflow-x: auto;
    overflow-y: scroll;
}
#setup_scroll_pc::-webkit-scrollbar{
    display: none;
}
#setup__ad_grp {
    cursor: pointer;
    width: 174px;
    height: 191px;
    left: 166px;
    top: 23px;
    overflow: hidden;
}
#Rectangle_51_setup_ {
    fill: transparent;
}
.Rectangle_51_setup_ {
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 191px;
    left: 0px;
    top: 0px;
    background:  rgba( 255, 255, 255, 0.7 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 5px );
    -webkit-backdrop-filter:  blur( 5px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#add_1_setup_ {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 65px;
    top: 58px;
    overflow: visible;
}
#Remark_lbl_setup_ {
    left: 63px;
    top: 117px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(4,4,4,1);
}
