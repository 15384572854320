#task_page_mob_side_menu_btn_d {
    z-index: 10;
    position: absolute;
    width: 27px;
    height: 18px;
    left: 10px;
    top: 21px;
    overflow: visible;
}
#side_menu_mid_d {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.side_menu_mid_d {
    overflow: visible;
    position: absolute;
    width: 30px;
    height: 3px;
    left: 0px;
    top: 9px;
    transform: matrix(1,0,0,1,0,0);
}
#side_menu_top_d {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.side_menu_top_d {
    overflow: visible;
    position: absolute;
    width: 30px;
    height: 3px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#side_menu_down_d {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.side_menu_down_d {
    overflow: visible;
    position: absolute;
    width: 30px;
    height: 3px;
    left: 0px;
    top: 18px;
    transform: matrix(1,0,0,1,0,0);
}