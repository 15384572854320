.mediaViewInfo {
    --web-view-name: pop up purchase;
    --web-view-id: pop_up_purchase;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_purchase;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
.purchasepopup_layout{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
#pop_up_purchase {
    position: absolute;
    width: 325px;
    height: 655px;
    overflow: hidden;
    --web-view-name: pop up purchase;
    --web-view-id: pop_up_purchase;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#pop_up_bg_pop__ourchase__bg {
    position: absolute;
    width: 325px;
    height: 655px;
    left: 0px;
    top: 0px;
    overflow: visible;
    background:  rgba( 255, 255, 255, 0.5 );
    box-shadow:  0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter:  blur( 10px );
    -webkit-backdrop-filter:  blur( 10px );
    border-radius:  10px;
    border:  1px solid rgba( 255, 255, 255, 0.18 );
}
#Icon_ionic-mpoppurchase {
    fill: rgba(28,26,26,1);
}
.Icon_ionic-mpoppurchase {
    cursor: pointer;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 18px;
    transform: matrix(1,0,0,1,281,9.9999) rotate(45deg);
    transform-origin: center;
    left: 13px;
    top: 0px;
}
#add_purchases_lbl_purchase_pop {
    left: 98px;
    top: 3px;
    position: absolute;
    overflow: visible;
    width: 138px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#add_purch_btn_grp:hover {
    left: 77.75px;
    top: 591.379px;
}
#add_purch_btn_grp {
    position: absolute;
    width: 167px;
    height: 44px;
    left: 70.25px;
    top: 590px;
    overflow: visible;
    transition: all .3s ease-out;
}
#pop_up_purch_btn {
    fill: rgba(252,135,135,1);
}
.pop_up_purch_btn {
    position: absolute;
    overflow: visible;
    width: 167px;
    height: 44px;
    left: 0px;
    top: 0px;
}
#Add_purch_pop_up {
    left: 32px;
    top: 11px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#add_purchase_scroll_pop_up {
    mix-blend-mode: normal;
    position: absolute;
    width: 325.728px;
    height: 481.772px;
    left: 0px;
    top: 47.728px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#Group_54 {
    position: absolute;
    width: 286.989px;
    height: 61px;
    left: 15.739px;
    top: 0px;
    overflow: visible;
}
#product_lbl_purchase_pop_up {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 53px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#product_input_purchase_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.product_input_purchase_pop_up {
    position: absolute;
    overflow: visible;
    width: 286.989px;
    height: 40px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#purchase_date_lbl_purchase_pop {
    left: 15.739px;
    top: 70.272px;
    position: absolute;
    overflow: visible;
    width: 102px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#expiry_date_input_purchase_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.expiry_date_input_purchase_pop {
    position: absolute;
    overflow: visible;
    width: 143.169px;
    height: 40px;
    left: 159.559px;
    top: 93.272px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#expiry_date_purchase_pop_up {
    left: 158.559px;
    top: 70.281px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#purchase_date_purchase_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.purchase_date_purchase_pop_up {
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 93.281px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#total_amount_lbl_purchase_pop_ {
    left: 15.739px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#total_amount_input_purchase_po {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.total_amount_input_purchase_po {
    z-index: 10;
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 335px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#status_lbl_purchase_pop_up {
    left: 159px;
    top: 531px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#status_input_purchase_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.status_input_purchase_pop_up {
    position: absolute;
    overflow: visible;
    width: 143.728px;
    height: 40px;
    left: 159px;
    top: 554px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#contact_staff_lbl_purchase_pop {
    left: 15.739px;
    top: 385px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#contact_staff_input_purchase_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.contact_staff_input_purchase_p {
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 408px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#staff_number_lbl_purchase_pop_ {
    left: 159px;
    top: 385px;
    position: absolute;
    overflow: visible;
    width: 95px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#staff_number_input_purchase_po {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.staff_number_input_purchase_po {
    position: absolute;
    overflow: visible;
    width: 143.728px;
    height: 40px;
    left: 159px;
    top: 408px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#approved_by_lbl_purchase_pop_u {
    left: 15.739px;
    top: 458px;
    position: absolute;
    overflow: visible;
    width: 92px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#approved_by_inpout_purchase_po {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.approved_by_inpout_purchase_po {
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 481px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#purchased_by_lbl_purchase_pop_ {
    left: 159px;
    top: 458px;
    position: absolute;
    overflow: visible;
    width: 95px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#purchase_by_input_purchase_pop {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.purchase_by_input_purchase_pop {
    position: absolute;
    overflow: visible;
    width: 143.728px;
    height: 40px;
    left: 159px;
    top: 481px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#approved_by_lbl_purchase_pop_u_d {
    left: 15.739px;
    top: 531.991px;
    position: absolute;
    overflow: visible;
    width: 67px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#approved_by_inpout_purchase_du {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.approved_by_inpout_purchase_du {
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 553.991px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#catrgory_lbl_purchase_pop_up {
    left: 15.739px;
    top: 144px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#category_input_purchase_pop_up {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.category_input_purchase_pop_up {
    position: absolute;
    overflow: visible;
    width: 133px;
    height: 40px;
    left: 15.739px;
    top: 167px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#item_cost_lbl_purchase_pop_up {
    left: 161.059px;
    top: 144px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#item_cost_input_purchase_pop_u {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.item_cost_input_purchase_pop_u {
    position: absolute;
    overflow: visible;
    width: 141.669px;
    height: 40px;
    left: 161.059px;
    top: 166px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#purchased_from_lbl_purchase_po {
    left: 161.471px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#purchased_from_input_purchase_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.purchased_from_input_purchase_ {
    z-index: 10;
    position: absolute;
    overflow: visible;
    width: 141.257px;
    height: 40px;
    left: 161.471px;
    top: 335px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#purchases_pop_up_grp {
    position: absolute;
    width: 289px;
    height: 68px;
    left: 13px;
    top: 226.272px;
    overflow: visible;
}
#add_charges_bdr_pop_up {
    fill: rgba(255,255,255,1);
}
.add_charges_bdr_pop_up {
    position: absolute;
    overflow: visible;
    width: 289px;
}
#add_charges_lbl_pfp_pop_up_inv {
    left: 6px;
    top: 6.446px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#additional_charges_text_enter {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.additional_charges_text_enter {
    position: relative;
    overflow: visible;
    width: 171.39px;
    height: 33.954px;
    left: 6px;
    margin-top: 5px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#add-button_purchases_pop_up {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 264px;
    top: 6.446px;
    overflow: visible;
}
#amount_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.amount_input_pfp {
    position: relative;
    overflow: visible;
    width: 70.123px;
    height: 33.95px;
    left: 8px;
    margin-top: 5px;
    font-size: 15px;
    border: 1px solid rgba(252,135,135,1);
    border-radius: 7px;
    padding: 5px;
}
#minus_remove_btn_pop {
    position: relative;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    left: 12px;
    overflow: visible;
}
#upload_invoice_lbl_purchase_po {
    left: 51px;
    top: 553px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Icon_feather-upload {
    position: absolute;
    width: 23.94px;
    height: 24px;
    left: 17px;
    top: 548px;
    overflow: visible;
}
#invoice_down_ {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_down_ {
    overflow: visible;
    position: absolute;
    width: 26.94px;
    height: 12px;
    left: 0px;
    top: 15px;
    transform: matrix(1,0,0,1,0,0);
}
#invoice_arrow_diagnal {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_arrow_diagnal {
    overflow: visible;
    position: absolute;
    width: 19.242px;
    height: 11.121px;
    left: 4px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#invoice_line_arrow {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.invoice_line_arrow {
    overflow: visible;
    position: absolute;
    width: 3px;
    height: 21px;
    left: 11.5px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Icon_feather-download {
    position: absolute;
    width: 23.941px;
    height: 26px;
    left: 161.059px;
    top: 546px;
    overflow: visible;
}
#arrow_bottom {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_bottom {
    overflow: visible;
    position: absolute;
    width: 26.941px;
    height: 12px;
    left: 0px;
    top: 17px;
    transform: matrix(1,0,0,1,0,0);
}
#arrow_angle {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_angle {
    overflow: visible;
    position: absolute;
    width: 19.242px;
    height: 11.121px;
    left: 4.941px;
    top: 10.5px;
    transform: matrix(1,0,0,1,0,0);
}
#arrow_line {
    fill: transparent;
    stroke: rgba(0,0,0,1);
    stroke-width: 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.arrow_line {
    overflow: visible;
    position: absolute;
    width: 3px;
    height: 21px;
    left: 12.441px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#download_invoice_lbl_purchase_ {
    left: 195px;
    top: 553px;
    position: absolute;
    overflow: visible;
    width: 104px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#allocated_to_lbl_purchase_pop_ {
    left: 15.739px;
    top: 604px;
    position: absolute;
    overflow: visible;
    width: 88px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#allocated_to_inpout_purchase_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.allocated_to_inpout_purchase_p {
    position: absolute;
    overflow: visible;
    width: 133.257px;
    height: 40px;
    left: 15.739px;
    top: 627px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
#allocated_on_lbl_purchase_pop_ {
    left: 158.739px;
    top: 604px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#allocated_on_inpout_purchase_p {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.allocated_on_inpout_purchase_p {
    position: absolute;
    overflow: visible;
    width: 143.261px;
    height: 40px;
    left: 158.739px;
    top: 627px;
    font-size: 15px;
    border: 2px solid rgba(252,135,135,1);
    border-radius: 10px;
    padding: 5px;
}
