#finance_manager_lbl_grp {
    position: absolute;
    width: calc(100% - 59%);
    height: 34px;
    right: calc(100% - 70%);
    top: 13px;
    overflow: visible;
    z-index: 10;
}
#Finance_Manager_one_time_task_ {
    left: 41px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: calc(100% - 32%);
    white-space: nowrap;
    text-align: center;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#logo_one_time_task_mob {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 0px;
    top: 0px;
    overflow: visible;
}