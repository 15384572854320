.mediaViewInfo {
    --web-view-name: mob login – 1;
    --web-view-id: mob_login__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: mob_login__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#mob_login__1 {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: mob login – 1;
    --web-view-id: mob_login__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#n_6388521_7199773 {
    position: absolute;
    width: 95%;
    height: calc(100% - 63%);
    top: calc(100% - 81%);
    overflow: visible;
    margin-right: 10px;
}
#mid_overall_grp_mob {
    position: absolute;
    width: 100%;
    height: 205px;
    top: calc(100% - 36%);
    overflow: visible;
    display: flex;
    justify-content: center;
}
#sign_in_brn_dj {
    fill: url(#sign_in_brn_dj);
}
.sign_in_brn_dj {
    position: absolute;
    overflow: visible;
    width: calc(100% - 25%);
    height: 45px;
    top: 160px;
}
#Sign_in_lbl {
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#username_box_input {
    fill: rgba(254,254,254,1);
}
.username_box_input {
    position: absolute;
    overflow: visible;
    width: 272px;
    height: 46px;
    top: 0px;
    border-radius: 5px;
    border: solid 2px;
    padding: 5px;
}
#password_box_input {
    fill: rgba(255,255,255,1);
}
.password_box_input {
    position: absolute;
    overflow: visible;
    width: 272px;
    height: 46px;
    top: 60px;
    border-radius: 5px;
    border: solid 2px;
    padding: 5px;
}
#Forgot_Password_lbl_mob {
    left: calc(100% - 46%);
    top: 108px;
    position: absolute;
    overflow: visible;
    width: 111px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#logo_img {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 19px;
    overflow: visible;
}
#Finance_Manager_lbl_ {
    top: calc(100% - 87%);
    z-index: 10;
    position: absolute;
    overflow: visible;
    width: 218px;
    white-space: nowrap;
    text-align: left;
    font-family: Berlin Sans FB;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #768691;
}