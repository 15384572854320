.loader {
  width: 60px;
  height: 60px;
  top: 40%;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 50px;
  height: 10px;
  background: #999;
  position: absolute;
  top: 70px;
  left: 06px;
  border-radius: 50%;
  animation: shadow324 1.2s backwards infinite;
}

.loader:after {
  background-image: url(../../../public/logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 1.2s linear infinite;
}

@keyframes jump7456 {
  0%, 100% {
    transform: translateY(0);
  }

  30%, 70% {
    transform: translateY(-40px);
  }

  60% {
    transform: translateY(-20px);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}
